import { useEffect, useState } from "react";
import axios from "axios";
import useDidMountEffect from "../components/Modal/ModalBody/intialRunUseEffect";

export default function useBookingsListPop(
  url,
  filterCustom,
  query,
  pageNumber,
  searchAdvanced,
  resetResults,
  offset = 20
) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [rowspop, setRowsPop] = useState([]);
  const [totalElements, setTotalElements] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [totalPages, setTotalPages] = useState(2);

  useEffect(() => {
    setRowsPop([]);
  }, [query]);

  useDidMountEffect(() => {
    setRowsPop([]);
  }, [searchAdvanced]);

  useDidMountEffect(() => {
    setRowsPop([]);
  }, [resetResults]);

  useEffect(() => {
    setLoading(true);
    setError(false);
    let cancel;
    axios({
      method: "GET",
      url: url,
      params: { page: pageNumber, offset: offset, q: query },
      cancelToken: new axios.CancelToken((c) => (cancel = c)),
    })
      .then((res) => {
        setRowsPop([]);
        setTotalElements(res.data.body.totalElements);
        setTotalPages(res.data.body.totalPages);
        setRowsPop((prevBooks) => {
          return [...new Set([...prevBooks, ...res.data.body])];
        });
        setLoading(false);
      })
      .catch((e) => {
        if (axios.isCancel(e)) return;
        setLoading(false);
        setError(true);
      });
    return () => cancel();
  }, [searchAdvanced, query, pageNumber, resetResults]);

  return { loading, error, rowspop, hasMore, totalElements, totalPages };
}
