import React, { useState, useEffect } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import ToastCommon from "../../toastCommon";
import { useToasts } from "react-toast-notifications";
import { ClientLable, HOSTMexxar } from "../../../configs/api-config";
import {
  Button,
  Input,
  Form,
  FormGroup,
  Label,
  Col,
  Alert,
  Spinner,
} from "reactstrap";
import useDidMountEffect from "./intialRunUseEffect";
import sortByCustomOrder from "../../../util/common/sortByCustomOrder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { UncontrolledTooltip } from "reactstrap/lib";
import { formatToHHMMSS } from "../../../util/common/formatToHHMMSS";
import store from "../../../redux/store";
import { gradesGetAction } from "../../../redux/actions/initialDataGetAction";
import { connect } from "react-redux";
import moment from "moment";

function ClientRateBookingModal(props) {
  const MexxarApiCandidateRate = HOSTMexxar + "candidate-rate-grade";

  const { addToast } = useToasts();
  const { register, handleSubmit } = useForm();
  const {
    closeModal,
    candidate,
    toggle,
    incommingPage,
    clientId,
    specialityId,
    departmentId,
    confirmRates,
    ratesConfirmed,
    reduxClients,
    reduxDepartments,
    reduxBands,
    reduxGrades,
    clientOptions,
    grade = -1,
  } = props;

  let initialRateTypes = [
    { type: "Rate Week Days", key: 0 },
    { type: "Rate Saturday", key: 1 },
    { type: "Rate Sunday", key: 2 },
    { type: "Rate Bank Holiday", key: 3 },
  ];

  const [clients, setClients] = useState(null);
  const [update, setUpdate] = useState(false);
  const [departments, setDepartments] = useState();
  const [currentDepartment, setCurrentDepartment] = useState(-1);
  const [editable, setEditable] = useState(false);
  const [currentClient, setCurrentClient] = useState(-1);
  const [currentGrade, setCurrentGrade] = useState(-1);
  const [grades, setGrades] = useState(null);

  const [escalationExpDate, setEscalationExpDate] = useState("");

  const [candidateData, setCandidateID] = useState(candidate.candidate);
  const [error, setError] = useState({ bool: false, message: "" });
  const [reset, setReset] = useState(false);
  const [combinationMismatchError, setCombinationMismatchError] =
    useState(false);
  const [rateTypes, setRateTypes] = useState(initialRateTypes);

  const [rateType, setRateType] = useState("Candidate");

  const [isDirty, setIsDirty] = useState(false);

  // Helper function to check if the value is changed
  const checkIfValueChanged = (newValue, oldValue) => {
    if (newValue !== oldValue) {
      setIsDirty(true); // Set isDirty to true if the value is different
    }
  };

  const toggleRates = (e) => {
    if (e.target.value === "Candidate") {
      setRateType(ClientLable);
    } else if (e.target.value === ClientLable) {
      setRateType("Candidate");
    }
  };

  const typeFinder = (data) => {
    let shortForm;
    let type;
    let key;
    switch (data) {
      case "WEEK":
        shortForm = data;
        type = "Rate Week Days";
        key = 0;
        break;
      case "SAT":
        shortForm = data;
        type = "Rate Saturday";
        key = 1;
        break;
      case "SUN":
        shortForm = data;
        type = "Rate Sunday";
        key = 2;
        break;
      case "BH":
        shortForm = data;
        type = "Rate Bank Holiday";
        key = 3;
        break;
    }
    return { shortForm: shortForm, type: type, key: key };
  };

  const getRates = (clientId, rateGradesId) => {
    setRateTypes(initialRateTypes);

    if (
      (rateType === "Candidate" && clientId === -1) ||
      currentDepartment === -1
    ) {
      return;
    }

    let typeOfRate =
      rateType === "Candidate"
        ? "candidate-rate-grade/candidateRateGrades"
        : "client-rate-grade/clientRateGrades";

    const url = `${HOSTMexxar}${typeOfRate}`;

    axios({
      method: "GET",
      url: url,
      params: {
        page: 1,
        offset: 20,
        clientId: clientId,
        clientWardId: currentDepartment,
        rateGradesId: rateGradesId,
        ...(rateType === "Candidate" && { candidateId: candidateData.id }), // Add candidateId only for 'Candidate'
      },
    }).then((res) => {
      const rates = res.data.body?.content || [];

      if (rates?.length > 0) {
        setUpdate(true);
      } else {
        setUpdate(false);
      }

      setEscalationExpDate(moment(rates?.[0]?.expireDate).format("YYYY-MM-DD"));

      let dayTypes = ["WEEK", "SAT", "SUN", "BH"];
      let updatedRates = dayTypes.map((dayType) => {
        const filterRates = (payMode, rateMode) => {
          return rates.find(
            (item) =>
              item.dayType === dayType &&
              item.payRollDayMode === payMode &&
              item.rateMode === rateMode
          );
        };

        const defaultDayRate = filterRates("DAY", "DEFAULT") || {};
        const defaultNightRate = filterRates("NIGHT", "DEFAULT") || {};
        const specialDayRate = filterRates("DAY", "SPECIAL") || {};
        const specialNightRate = filterRates("NIGHT", "SPECIAL") || {};

        return {
          type: typeFinder(dayType).type,
          key: typeFinder(dayType).key,
          shortForm: dayType,

          nId: defaultNightRate.id || "",
          dId: defaultDayRate.id || "",
          sDId: specialDayRate.id || "",
          sNId: specialNightRate.id || "",

          nStart: defaultNightRate.startTime || "",
          nEnd: defaultNightRate.endTime || "",
          nRate: defaultNightRate.rate || "",
          nChargeRate: defaultNightRate.chargeRate || "",

          dStart: defaultDayRate.startTime || "",
          dEnd: defaultDayRate.endTime || "",
          dRate: defaultDayRate.rate || "",
          dChargeRate: defaultDayRate.chargeRate || "",

          bRate: specialDayRate.rate || specialNightRate.rate || "",
        };
      });

      setRateTypes(updatedRates);
      const allEmpty = updatedRates.every(
        (rate) => !rate.dStart && !rate.dEnd && !rate.nStart && !rate.nEnd //since these values are not presented we assume that the rates are not present
      );

      if (rateType === "Candidate") {
        let clientRates = [];
        // get the CHARGE rates relavent to client and get dStart,dEnd,nStart,nEnd from the clients. and replace above  rateTypes object's dStart,dEnd,nStart,nEnd AND CHARGE RATES

        let clientRatesUrl = HOSTMexxar + "client-rate-grade/clientRateGrades";

        axios({
          method: "GET",
          url: clientRatesUrl,
          params: {
            page: 1,
            offset: 20,
            clientId: clientId,
            clientWardId: currentDepartment,
            rateGradesId: rateGradesId,
          },
        }).then((clientRes) => {
          clientRates = clientRes.data.body?.content || [];

          setEscalationExpDate(
            moment(clientRates?.[0]?.expireDate).format("YYYY-MM-DD")
          );

          if (clientRates?.length === 0) {
            let defaultWardID = departments?.find(
              (x) => x.wardName === "Default Ward"
            );

            if (!defaultWardID) {
              setError({
                bool: true,
                message:
                  "Default ward has not been added. Please add the default ward to the client first.",
              });
              return;
            }

            //get default rate for the given combination of with relevent grade and default department

            let defaultClientRatesUrl =
              HOSTMexxar + "client-rate-grade/clientRateGrades";

            axios({
              method: "GET",
              url: defaultClientRatesUrl,
              params: {
                page: 1,
                offset: 20,
                clientId: clientId,
                clientWardId: defaultWardID?.id,
                rateGradesId: rateGradesId,
              },
            }).then((clientRes) => {
              clientRates = clientRes.data.body?.content || [];
              setEscalationExpDate(
                moment(clientRates?.[0]?.expireDate).format("YYYY-MM-DD")
              );

              const allEmpty = clientRates.every(
                (rate) =>
                  !rate.startTime &&
                  !rate.endTime &&
                  !rate.chargeRate &&
                  !rate.rate
                //since these values are not presented we assume that the rates are not present
              );

              if (clientRates?.length === 0 || allEmpty) {
                //if get default rate for the given grade is not set, go fot the flat rate

                setError({
                  bool: true,
                  crutial: false,
                  message:
                    "Warning: Default Ward/Department rates have not been added for selected Grade. Please add default Ward/Department rates to the client first through " +
                    ClientLable +
                    " from side navigation tab.",
                });
              } else {
                //if get default rate for the given combination of speciality/band/role is available go with those rates
                updatedRates = updateRatesWithClientData(
                  updatedRates,
                  clientRates
                );

                setRateTypes(updatedRates);

                const allEmpty = updateRatesWithClientData(
                  updatedRates,
                  clientRates
                ).every(
                  (rate) =>
                    !rate.dStart && !rate.dEnd && !rate.nStart && !rate.nEnd
                );

                if (allEmpty) {
                  setError({
                    bool: true,
                    crutial: true,
                    message:
                      "The Start/End is not setup for the " +
                      ClientLable +
                      "for selected Grade" +
                      " .Please configure it first.",
                  });
                } else {
                  setError({
                    bool: true,
                    crutial: false,
                    message:
                      "The Start/End times and Charge Rates you are seeing are related to the client's default rate values.",
                  });
                }
              }
            });
          }

          // Merging client rates into candidate rates
          // Map the client's rates to update updatedRates
          updatedRates = updateRatesWithClientData(updatedRates, clientRates);
          // setError({
          //   bool: true,
          //   crutial: false,
          //   message:
          //     "The Start/End times and Charge Rates you are seeing correspond to the client's specified combinations above.",
          // });

          setRateTypes(updatedRates);
        });
      }
    });
  };

  const updateRatesWithClientData = (updatedRates, clientRates) => {
    return updatedRates.map((rate) => {
      const clientDayRate = clientRates.find(
        (clientRate) =>
          clientRate.dayType === rate.shortForm &&
          clientRate.payRollDayMode === "DAY" &&
          clientRate.rateMode === "DEFAULT"
      );

      const clientNightRate = clientRates.find(
        (clientRate) =>
          clientRate.dayType === rate.shortForm &&
          clientRate.payRollDayMode === "NIGHT" &&
          clientRate.rateMode === "DEFAULT"
      );

      return {
        ...rate,
        nStart: clientNightRate
          ? clientNightRate.startTime.slice(0, 5)
          : rate.nStart.slice(0, 5),
        nEnd: clientNightRate
          ? clientNightRate.endTime.slice(0, 5)
          : rate.nEnd.slice(0, 5),
        dStart: clientDayRate
          ? clientDayRate.startTime.slice(0, 5)
          : rate.dStart.slice(0, 5),
        dEnd: clientDayRate
          ? clientDayRate.endTime.slice(0, 5)
          : rate.dEnd.slice(0, 5),
        nChargeRate: clientNightRate
          ? clientNightRate.chargeRate
          : rate.nChargeRate,
        dChargeRate: clientDayRate
          ? clientDayRate.chargeRate
          : rate.dChargeRate,
      };
    });
  };

  useEffect(() => {
    setGrades(reduxGrades);
    setCurrentGrade(grade ? grade : reduxGrades?.[0]?.id);
  }, [reduxClients, reduxDepartments, reduxBands, reduxGrades]);

  useEffect(() => {
    store.dispatch(gradesGetAction());
  }, []);

  useEffect(() => {
    const setCandidateData = (candidate) => {
      if (incommingPage === "addBooking") {
        setClients(clientOptions);
        setCurrentClient(clientId);
      } else {
        if (candidate?.preferredClients?.length) {
          setClients(candidate?.preferredClients);
          setCurrentClient(candidate?.preferredClients[0].id);
        }
      }
    };

    if (incommingPage === "addBooking") {
      setCandidateData(candidate?.candidate);
    } else if (candidate) {
      setCandidateData(candidate?.candidate);
    }
  }, [candidate, incommingPage, clientId, specialityId]);

  useEffect(() => {
    if (currentClient) {
      getRates(currentClient, currentGrade);
    }
  }, [
    currentClient,
    currentDepartment,
    currentGrade,
    rateType,
    candidate,
    reset,
  ]);

  useDidMountEffect(() => {
    axios
      .get(HOSTMexxar + "client-wards/clients/" + currentClient)
      .then((res) => {
        let wards = res.data.body;
        // Sort array so that the "Default Ward" is first, "Escalation Ward" in second of the list
        const sortedWards = sortByCustomOrder(
          wards,
          ["Default Ward", "Escalation Rates"],
          "wardName"
        );

        setDepartments(sortedWards);

        if (incommingPage === "addBooking") {
          const matchingWard = sortedWards.find(
            (ward) => ward.wardId === departmentId?.id
          );
          if (matchingWard) {
            setCurrentDepartment(matchingWard.id); // Set to the matched department
          } else {
            setCurrentDepartment(sortedWards[0]?.id); // Set to the first if no match found
          }
        } else {
          if (sortedWards.length > 0) {
            setCurrentDepartment(sortedWards[0]?.id);
          } else {
            setCurrentDepartment(-1);
          }
        }
      })
      .catch((err) => {
        // console.log("err", err);
      });
  }, [currentClient]);

  const confirmRatesCheck = () => {
    setError({
      bool: false,
      message: "",
      crutial: false,
    });
    const allEmpty = rateTypes.every(
      (rate) => rate.nRate === "" && rate.dRate === ""
    );
    if (allEmpty) {
      setError({
        bool: true,
        message:
          "The rates are not set up, Please setup and Save First or Please double-check and confirm again.",
        crutial: true,
      });
      return;
    } else {
      confirmRates(rateTypes);
    }
  };

  const onSubmit = (data) => {
    console.log(data);

    //check if this is an update or new entry

    let expDateTemp = grades?.some(
      (grade) => grade.id === Number(currentGrade) && grade.escalation
    )
      ? true
      : false;
    let tempArray = [];
    if (update == false) {
      for (let i = 0; i < 4; i++) {
        // let BonusRate = Number(data[i + "BonusRate"]);
        // let BonusChargeRate = Number(data[i + "BonusChargeRate"]);
        let DayRate = Number(data[i + "DayRate"]);
        let DStartTime = formatToHHMMSS(data[i + "DStartTime"]);
        let DEndTime = formatToHHMMSS(data[i + "DEndTime"]);
        let NightRate = Number(data[i + "NightRate"]);
        let NStartTime = formatToHHMMSS(data[i + "NStartTime"]);
        let NEndTime = formatToHHMMSS(data[i + "NEndTime"]);
        let NightChargeRate = Number(data[i + "NightChargeRate"]);
        let DayChargeRate = Number(data[i + "DayChargeRate"]);

        tempArray.push(
          //......Bonus Day Rate .......//
          // {
          //   dayType: i,
          //   payRollDayMode: 0, // This should be 0 for Day Rates
          //   rate: BonusRate,
          //   // chargeRate: BonusChargeRate,
          //   rateMode: 1,
          //   candidateId: candidateData.id,
          //   specialityId: Number(currentSpeciality),
          //   roleId: candidateData.candidateRole.id,
          //   bandId: candidateData.candidateBand.id,
          //   startTime: DStartTime,
          //   endTime: DEndTime,
          //   clientId: currentClient,
          //   clientWardId: currentDepartment,
          // },
          // //......Bonus Night Rate .......//
          // {
          //   dayType: i,
          //   payRollDayMode: 1, // This should be 1 for Night Rates
          //   rate: BonusRate,
          //   // chargeRate: BonusChargeRate,
          //   rateMode: 1,
          //   candidateId: candidateData.id,
          //   specialityId: Number(currentSpeciality),
          //   roleId: candidateData.candidateRole.id,
          //   bandId: candidateData.candidateBand.id,
          //   startTime: NStartTime,
          //   endTime: NEndTime,
          //   clientId: currentClient,
          //   clientWardId: currentDepartment,
          // },
          //......Day Rate.......//
          {
            dayType: i,
            payRollDayMode: 0, // This should be 0 for Day Rates
            rate: DayRate,
            chargeRate: DayChargeRate,
            rateMode: 0,
            candidateId: candidateData.id,
            rateGradesId: Number(currentGrade),
            startTime: DStartTime,
            endTime: DEndTime,
            clientId: currentClient,
            clientWardId: currentDepartment,
            expireDate: expDateTemp ? escalationExpDate : "",
          },
          //......Night Rate.......//
          {
            dayType: i,
            payRollDayMode: 1, // This should be 1 for Night Rates
            rate: NightRate,
            chargeRate: NightChargeRate,
            rateMode: 0,
            candidateId: candidateData.id,
            rateGradesId: Number(currentGrade),
            startTime: NStartTime,
            endTime: NEndTime,
            clientId: currentClient,
            clientWardId: currentDepartment,
            expireDate: expDateTemp ? escalationExpDate : "",
          }
        );
      }
    } else {
      for (let i = 0; i < 4; i++) {
        // let BonusRate = Number(data[i + "BonusRate"]);
        // let BonusChargeRate = Number(data[i + "BonusChargeRate"]);
        let DayRate = Number(data[i + "DayRate"]);
        let DayChargeRate = Number(data[i + "DayChargeRate"]);
        let DStartTime = formatToHHMMSS(data[i + "DStartTime"]);
        let DEndTime = formatToHHMMSS(data[i + "DEndTime"]);
        let NightRate = Number(data[i + "NightRate"]);
        let NightChargeRate = Number(data[i + "NightChargeRate"]);
        let NStartTime = formatToHHMMSS(data[i + "NStartTime"]);
        let NEndTime = formatToHHMMSS(data[i + "NEndTime"]);
        let DayRateId = data[i + "DayRate" + "ID"];
        let NightRateId = data[i + "NightRate" + "ID"];
        // let BonusRateDayId = data[i + "BonusRateD" + "ID"];
        // let BonusRateNightId = data[i + "BonusRateN" + "ID"];

        tempArray.push(
          //......Bonus Day Rate .......//
          // {
          //   id: Number(BonusRateDayId),
          //   dayType: i,
          //   payRollDayMode: 0, // This should be 0 for Day Rates
          //   rate: BonusRate,
          //   // chargeRate: BonusChargeRate,
          //   rateMode: 1,
          //   candidateId: candidateData.id,
          //   specialityId: Number(currentSpeciality),
          //   roleId: candidateData.candidateRole.id,
          //   bandId: candidateData.candidateBand.id,
          //   startTime: DStartTime,
          //   endTime: DEndTime,
          //   clientId: currentClient,
          //   clientWardId: currentDepartment,
          // },

          // //......Bonus Night Rate .......//
          // {
          //   id: Number(BonusRateNightId),
          //   dayType: i,
          //   payRollDayMode: 1, // This should be 1 for Night Rates
          //   rate: BonusRate,
          //   // chargeRate: BonusChargeRate,
          //   rateMode: 1,
          //   candidateId: candidateData.id,
          //   specialityId: Number(currentSpeciality),
          //   roleId: candidateData.candidateRole.id,
          //   bandId: candidateData.candidateBand.id,
          //   startTime: NStartTime,
          //   endTime: NEndTime,
          //   clientId: currentClient,
          //   clientWardId: currentDepartment,
          // },
          //......Day Rate.......//
          {
            id: Number(DayRateId),
            dayType: i,
            payRollDayMode: 0, // This should be 0 for Day Rates
            rate: DayRate,
            chargeRate: DayChargeRate,
            rateMode: 0,
            candidateId: candidateData.id,
            rateGradesId: Number(currentGrade),
            startTime: DStartTime,
            endTime: DEndTime,
            clientId: currentClient,
            clientWardId: currentDepartment,
            expireDate: expDateTemp ? escalationExpDate : "",
          },
          //......Night Rate.......//
          {
            id: Number(NightRateId),
            dayType: i,
            payRollDayMode: 1, // This should be 1 for Night Rates
            rate: NightRate,
            chargeRate: NightChargeRate,
            rateMode: 0,
            candidateId: candidateData.id,
            rateGradesId: Number(currentGrade),
            startTime: NStartTime,
            endTime: NEndTime,
            clientId: currentClient,
            clientWardId: currentDepartment,
            expireDate: expDateTemp ? escalationExpDate : "",
          }
        );
      }
    }

    // Collect promises for API calls
    const apiPromises = tempArray.map((data) => {
      return update
        ? axios.put(MexxarApiCandidateRate, data)
        : axios.post(MexxarApiCandidateRate, data);
    });

    // Wait for all API calls to complete
    Promise.all(apiPromises)
      .then((responses) => {
        const allSuccess = responses.every(
          (res) => res.data.status === "Success"
        );
        if (allSuccess) {
          ToastCommon(true, "success", addToast);
          setIsDirty(false);
          setReset(!reset);
          if (incommingPage !== "addBooking") {
            closeModal(true);
          }
        } else {
          ToastCommon(true, "error", addToast);
        }
      })
      .catch((err) => {
        ToastCommon(true, "error", addToast);
      });
  };

  //Bellow shows the coping mechanisum Day rates of Satuday Sunday Bnak holiday to nights and Sunday rates to Bank holidays

  const handleRateChange = (key, rateType, value) => {
    const updatedRates = [...rateTypes]; // Copy the rateTypes array

    // Update the specific rate for the given key and rateType
    updatedRates[key] = { ...updatedRates[key], [rateType]: value };

    // 1. Copy NightRate to DayRate for Saturday, Sunday, and Bank Holiday
    if (rateType === "nRate") {
      if ([1, 2, 3].includes(key)) {
        // If the key is Saturday (1), Sunday (2), or Bank Holiday (3)
        updatedRates[key] = {
          ...updatedRates[key],
          dRate: value, // Copy NightRate to DayRate
        };
      }
    }

    // 2. Copy Sunday rates to Bank Holiday rates when Sunday rates change
    if (key === 2 && rateType === "nRate") {
      // If the rate is for Sunday (key 2)
      updatedRates[3] = {
        ...updatedRates[3],
        nRate: updatedRates[2].nRate, // Copy Sunday NightRate to Bank Holiday NightRate
        dRate: updatedRates[2].dRate, // Copy Sunday DayRate to Bank Holiday DayRate
      };
    }

    // Apply the updated rates to the state
    setRateTypes(updatedRates);
  };

  useDidMountEffect(() => {
    if (
      currentClient != clientId ||
      currentDepartment != departmentId.wardId ||
      currentGrade != grade
    ) {
      setCombinationMismatchError(true);
    } else {
      setCombinationMismatchError(false);
    }
  }, [currentClient, currentGrade, currentDepartment]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup row>
        <Label sm={6}>{rateType} rates for the following combination</Label>
        <Col sm={6} className="d-flex justify-content-end ">
          <Button value={rateType} color="info" onClick={(e) => toggleRates(e)}>
            {rateType} Rates
          </Button>
        </Col>
      </FormGroup>{" "}
      <FormGroup row>
        <Label sm={3}>Grade </Label>
        <Col sm={8}>
          <Input
            type="select"
            name="grade"
            value={currentGrade && currentGrade}
            innerRef={register({
              required: {
                value: true,
                message: "This field is required!",
              },
            })}
            onChange={(selected) => setCurrentGrade(selected.target.value)}
          >
            <option value="-1">Select</option>
            {grades &&
              grades?.map((grade, key) => (
                <option value={grade.id} key={key}>
                  {grade.name} {grade.escalation && "- Escalation"}
                </option>
              ))}
          </Input>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label
          sm={3}
          hidden={
            grades?.some(
              (grade) => grade.id === Number(currentGrade) && grade.escalation
            )
              ? false
              : true
          }
        >
          Exp Date
        </Label>
        <Col sm={8}>
          <Input
            type="date"
            name="expDate"
            disabled
            defaultValue={escalationExpDate}
            hidden={
              grades?.some(
                (grade) => grade.id === Number(currentGrade) && grade.escalation
              )
                ? false
                : true
            }
            // onChange={(e) => setEscalationExpDate(e.target.value)}
            innerRef={register({
              required: {
                value: grades?.some(
                  (grade) =>
                    grade.id === Number(currentGrade) && grade.escalation
                )
                  ? true
                  : false,
                message: "This field is required!",
              },
            })}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3}>{ClientLable} Name</Label>
        <Col sm={9}>
          {clients != null && clients != undefined ? (
            <Input
              type="select"
              name="hospital"
              innerRef={register}
              value={currentClient}
              onChange={(data) => setCurrentClient(data.target.value)}
            >
              {clients.map((client, key) => (
                <option value={client.id} key={key}>
                  {client.name}
                </option>
              ))}
            </Input>
          ) : (
            <Spinner
              className="mx-2  "
              style={{
                height: "20px",
                width: "20px",
                marginRight: "5px",
              }}
              loading={true}
            />
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3}>Department Name</Label>
        <Col sm={9}>
          {departments != null && departments != undefined ? (
            <Input
              type="select"
              name="deapartment"
              // defaultValue={deapartment.id}
              value={currentDepartment}
              innerRef={register}
              onChange={(selected) =>
                setCurrentDepartment(selected.target.value)
              }
            >
              {departments.map((department, key) => (
                <option value={department.id} key={key}>
                  {department.wardName}
                </option>
              ))}
            </Input>
          ) : (
            <Spinner
              className="mx-2  "
              style={{
                height: "20px",
                width: "20px",
                marginRight: "5px",
              }}
              loading={true}
            />
          )}
        </Col>
      </FormGroup>{" "}
      <FormGroup row>
        <Col sm={12}>
          <Alert color={"info"} className="mt-2 text-sm">
            <li>
              {" "}
              To assign or view rates without specifying Wards/Department, use
              'Default Ward'.
            </li>
            <li>
              {" "}
              To define rates by Ward/Department, select the relevant
              Ward/Department under each {ClientLable}.
            </li>
            <li>
              {" "}
              Select 'Escalation Rates' from the Grades list to view or set
              "Escalation Rates".
            </li>
          </Alert>
        </Col>
      </FormGroup>
      <div className="col-12 col-sm-12 ">
        <div>
          <div className="card-body">
            {rateTypes.map((data, id) => {
              return (
                <div className="d-flex flex-row" key={id}>
                  <div
                    className="d-flex justify-content-start align-items-center"
                    style={{ width: "300px" }}
                  >
                    {data.type}
                  </div>
                  <div
                    className="d-flex flex-column "
                    style={{ width: "600px" }}
                  >
                    {/* <div className="d-flex justify-content-center m-1">Week Days</div> */}
                    <div className="d-flex flex-row justify-content-center m-1">
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ width: "120px" }}
                      >
                        {rateType === ClientLable ? "Night" : ""}
                      </div>
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ width: "80px" }}
                      >
                        N.P.R
                        <FontAwesomeIcon
                          id="NPR"
                          className="ml-1"
                          icon={faExclamationCircle}
                        />
                      </div>
                      <UncontrolledTooltip target="NPR">
                        Night Pay Rate
                      </UncontrolledTooltip>
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ width: "80px" }}
                      >
                        N.C.R
                        <FontAwesomeIcon
                          id="NCR"
                          className="ml-1"
                          icon={faExclamationCircle}
                        />
                      </div>
                      <UncontrolledTooltip target="NCR">
                        Night Charge Rate
                      </UncontrolledTooltip>
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ width: "120px" }}
                      >
                        {rateType === ClientLable ? "Day" : ""}
                      </div>
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ width: "80px" }}
                      >
                        D.P.R
                        <FontAwesomeIcon
                          id="DPR"
                          className="ml-1"
                          icon={faExclamationCircle}
                        />
                      </div>
                      <UncontrolledTooltip target="DPR">
                        Day Pay Rate
                      </UncontrolledTooltip>
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ width: "80px" }}
                      >
                        D.C.R
                        <FontAwesomeIcon
                          id="DCR"
                          className="ml-1"
                          icon={faExclamationCircle}
                        />
                      </div>
                      <UncontrolledTooltip target="DCR">
                        Day Charge Rate
                      </UncontrolledTooltip>
                      {/* <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ width: "80px" }}
                      >
                        Bonus
                      </div> */}
                    </div>
                    <div className="d-flex flex-row justify-content-center">
                      <div className="d-flex flex-column">
                        <div
                          className="d-flex justify-content-center align-items-center m-1"
                          style={{ width: "100px" }}
                        >
                          <Input
                            type="time"
                            hidden={rateType === "Candidate"}
                            placeholder=""
                            name={data.key + "NStartTime"}
                            defaultValue={data.nStart}
                            value={data.nStart}
                            innerRef={register({
                              required: {
                                value: true,
                                message: "This field is required!",
                              },
                            })}
                          />
                        </div>
                        <div
                          className="d-flex justify-content-center align-items-center m-1"
                          style={{ width: "100px" }}
                        >
                          <Input
                            type="time"
                            hidden={rateType === "Candidate"}
                            placeholder=""
                            name={data.key + "NEndTime"}
                            defaultValue={data.nEnd}
                            value={data.nEnd}
                            innerRef={register({
                              required: {
                                value: true,
                                message: "This field is required!",
                              },
                            })}
                          />
                        </div>
                      </div>
                      <div
                        className="d-flex justify-content-center align-items-center m-1"
                        style={{ width: "80px" }}
                      >
                        <Input
                          type="text"
                          name={`${data.key}NightRate`}
                          defaultValue={data.nRate}
                          value={data.nRate}
                          onChange={(e) => {
                            const newValue = e.target.value
                              .replace(/[^0-9.]/g, "") // Remove non-numeric and non-decimal point characters
                              .replace(/^(\d+\.?\d{0,2}).*$/, "$1"); // Restrict to two decimal places
                            handleRateChange(data.key, "nRate", newValue); // Call handleRateChange
                            e.target.style.color =
                              parseFloat(newValue) > data.nChargeRate
                                ? "red"
                                : "inherit";
                            checkIfValueChanged(
                              newValue,
                              e.target.defaultValue
                            );
                          }}
                          style={{
                            color:
                              parseFloat(data.nRate || 0) > data.nChargeRate
                                ? "red"
                                : "inherit",
                          }}
                          innerRef={register({
                            required: {
                              value: true,
                              message: "This field is required!",
                            },
                          })}
                        />

                        <Input
                          type="hidden"
                          name={data.key + "NightRate" + "ID"}
                          defaultValue={data.nId}
                          innerRef={register({
                            required: {
                              value: false,
                              message: "",
                            },
                          })}
                        />
                      </div>

                      <div
                        className="d-flex justify-content-center align-items-center m-1"
                        style={{ width: "80px" }}
                      >
                        <Input
                          type="text"
                          readOnly
                          name={data.key + "NightChargeRate"}
                          defaultvalue={data.nChargeRate}
                          value={data.nChargeRate}
                          onChange={(e) =>
                            checkIfValueChanged(
                              e.target.value,
                              e.target.defaultValue
                            )
                          }
                          innerRef={register({
                            required: {
                              value: true,
                              message: "This field is required!",
                            },
                          })}
                        />

                        {/* <Input
                          type="hidden"
                          name={rateType.key + "NightChargeRate" + "ID"}
                          defaultValue={rateType.nId}
                          innerRef={register({
                            required: {
                              value: false,
                              message: "",
                            },
                          })}
                        /> */}
                      </div>
                      <div className="d-flex flex-column">
                        <div
                          className="d-flex justify-content-center align-items-center m-1"
                          style={{ width: "100px" }}
                        >
                          <Input
                            type="time"
                            hidden={rateType === "Candidate"}
                            placeholder=""
                            name={data.key + "DStartTime"}
                            defaultValue={data.dStart}
                            value={data.dStart}
                            onChange={(e) =>
                              checkIfValueChanged(
                                e.target.value,
                                e.target.defaultValue
                              )
                            }
                            innerRef={register({
                              required: {
                                value: true,
                                message: "This field is required!",
                              },
                            })}
                          />
                        </div>
                        <div
                          className="d-flex justify-content-center align-items-center m-1"
                          style={{ width: "100px" }}
                        >
                          <Input
                            type="time"
                            hidden={rateType === "Candidate"}
                            placeholder=""
                            name={data.key + "DEndTime"}
                            defaultValue={data.dEnd}
                            value={data.dEnd}
                            onChange={(e) =>
                              checkIfValueChanged(
                                e.target.value,
                                e.target.defaultValue
                              )
                            }
                            innerRef={register({
                              required: {
                                value: true,
                                message: "This field is required!",
                              },
                            })}
                          />
                        </div>
                      </div>
                      <div
                        className="d-flex justify-content-center align-items-center m-1"
                        style={{ width: "80px" }}
                      >
                        <Input
                          type="text"
                          name={`${data.key}DayRate`}
                          defaultValue={data.dRate}
                          value={data.dRate}
                          onChange={(e) => {
                            const newValue = e.target.value
                              .replace(/[^0-9.]/g, "") // Remove non-numeric and non-decimal point characters
                              .replace(/^(\d+\.?\d{0,2}).*$/, "$1"); // Restrict to two decimal places
                            handleRateChange(data.key, "dRate", newValue); // Call handleRateChange
                            e.target.style.color =
                              parseFloat(newValue) > data.dChargeRate
                                ? "red"
                                : "inherit";
                            checkIfValueChanged(
                              newValue,
                              e.target.defaultValue
                            );
                          }}
                          style={{
                            color:
                              parseFloat(data.dRate || 0) > data.dChargeRate
                                ? "red"
                                : "inherit",
                          }}
                          innerRef={register({
                            required: {
                              value: true,
                              message: "This field is required!",
                            },
                          })}
                        />

                        <Input
                          type="hidden"
                          name={data.key + "DayRate" + "ID"}
                          defaultValue={data.dId}
                          innerRef={register({
                            required: {
                              value: false,
                              message: "",
                            },
                          })}
                        />
                      </div>
                      <div
                        className="d-flex justify-content-center align-items-center m-1"
                        style={{ width: "80px" }}
                      >
                        <Input
                          type="text"
                          readOnly
                          name={data.key + "DayChargeRate"}
                          defaultValue={data.dChargeRate}
                          value={data.dChargeRate}
                          onChange={(e) =>
                            checkIfValueChanged(
                              e.target.value,
                              e.target.defaultValue
                            )
                          }
                          innerRef={register({
                            required: {
                              value: true,
                              message: "This field is required!",
                            },
                          })}
                        />
                        {/* <Input
                          type="hidden"
                          name={rateType.key + "DayChargeRate" + "ID"}
                          defaultValue={rateType.dId}
                          innerRef={register({
                            required: {
                              value: false,
                              message: "",
                            },
                          })}
                        /> */}
                      </div>
                      {/* <div
                        className="d-flex justify-content-center align-items-center m-1"
                        style={{ width: "60px" }}
                      >
                        <Input
                          type="text"
                          name={data.key + "BonusRate"}
                          defaultValue={data.bRate}
                          innerRef={register({
                            required: {
                              value: true,
                              message: "This field is required!",
                            },
                          })}
                        />

                        <Input
                          type="hidden"
                          name={data.key + "BonusRateD" + "ID"}
                          defaultValue={data.sDId}
                          innerRef={register({
                            required: {
                              value: false,
                              message: "",
                            },
                          })}
                        />

                        <Input
                          type="hidden"
                          name={data.key + "BonusRateN" + "ID"}
                          defaultValue={data.sNId}
                          innerRef={register({
                            required: {
                              value: false,
                              message: "",
                            },
                          })}
                        />
                      </div> */}
                    </div>
                  </div>
                </div>
              );
            })}

            {incommingPage === "addBooking" && error?.bool && (
              <Alert
                color={`${error?.crutial ? "danger" : "info"}`}
                className="my-2 text-sm"
              >
                {error?.message}
              </Alert>
            )}
            {incommingPage === "addBooking" && (
              <Alert color="warning" className="my-2 text-sm">
                1. Confirm Rates: Please verify that the rates have been
                populated in the fields above. <br />
                2. Save: If you’ve made any changes or added new rates, be sure
                to click "Save."
                <br />
                3. Proceed with Booking: Once you’ve completed either of the
                above actions, you can proceed to create the booking.
              </Alert>
            )}
            {rateType === "Candidate" && incommingPage !== "addBooking" && (
              <Alert color="warning" className="my-2 text-sm">
                Press save only if you need to make changes to the default
                values.
              </Alert>
            )}
            {rateType !== "Candidate" && (
              <Alert color="warning" className="my-2 text-sm">
                Switch to "Candidate Rates" to see "Save" or "Confirm Rates"
                Button from Top Right Corner
              </Alert>
            )}

            {rateType === "Candidate" &&
              incommingPage === "addBooking" &&
              combinationMismatchError && (
                <Alert color="danger" className="my-2 text-sm">
                  The confirmed combination of Grade , {ClientLable}, and
                  Department does not match the booking you are trying to
                  create. Please double-check and correct it either from the
                  previous window or here before confirming.
                </Alert>
              )}
            {isDirty && (
              <Alert color="danger" style={{ color: "red" }}>
                You have unsaved changes! Please Update
              </Alert>
            )}
            <div className="d-flex justify-content-end mt-3 mr-3">
              {rateType === "Candidate" && (
                <>
                  {incommingPage === "addBooking" && (
                    <Button
                      color="success"
                      type="button"
                      disabled={
                        (error?.bool && error?.crutial) ||
                        isDirty ||
                        combinationMismatchError
                      }
                      onClick={() => confirmRatesCheck()}
                      className=""
                    >
                      Confirm Rates
                    </Button>
                  )}
                  <Button color="success" type="submit" className="mx-3">
                    Save
                  </Button>
                </>
              )}
              <Button color="secondary" onClick={closeModal}>
                Close
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
}

function mapStateToProps(state) {
  return {
    reduxClients: state.initialDataGetReducer.clients,
    reduxDepartments: state.initialDataGetReducer.departments,
    reduxBands: state.initialDataGetReducer.bands,
    reduxGrades: state.initialDataGetReducer.grades,
  };
}

export default connect(mapStateToProps, {})(ClientRateBookingModal);
