import React, { useEffect, useRef, useState } from "react";
import { saveAs } from "file-saver";

import {
  faPhone,
  faChevronLeft,
  faChevronRight,
  faExpandAlt,
  faExclamationCircle,
  faAngleLeft,
  faAngleRight,
  faFileDownload,
  faCopy,
  faFileArchive,
  faTrash,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Input,
  Label,
  Form,
  FormGroup,
  Alert,
  Badge,
  UncontrolledTooltip,
  Row,
  Col,
  Collapse,
} from "reactstrap";
import {
  Add_New_DOCUMENT,
  REGISTER_CANDIDATE,
  HOSTMexxar,
} from "../../../configs/api-config";

import axios from "axios";
import moment from "moment";
import "./docView.style.css";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
//import PDFViewer from "pdf-viewer-reactjs";
import "react-dropzone-uploader/dist/styles.css";
import Header from "../../../widgets/header/header";
import { ProfileStatus } from "../../../util/common";

import { useToasts } from "react-toast-notifications";
import SingleFileAutoSubmit from "./SingleFileAutoSubmit";
import ToastCommon from "../../../components/toastCommon";
import { filter, forEach, findIndex, split, isNull } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalComponent from "../../../components/Modal/modalComponent";
import AuditReject from "../../../components/Modal/ModalBody/auditReject";
import AssignConsultant from "../../../components/Modal/ModalBody/assignConsultant";
import useDidMountEffect from "../../../components/Modal/ModalBody/intialRunUseEffect";
import { useIsMounted } from "../../../customHooks/useIsMounted";
import { downloadDocs } from "../../../util/common/downloadDocument";
import DocumentLoader from "../../../components/Modal/ModalBody/documentLoader.js";
import copyToClipboard from "../../../util/common/copyToClipboard";
import { Document, Page, pdfjs } from "react-pdf";
import Select from "react-select";
import DeleteDocument from "../../../components/Modal/ModalBody/deleteDocument.js";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min.js";
import DateInput from "../../../util/common/DateInput.js";
import dateFormatter from "../../../util/common/dateFormatter.js";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const MexxarAPI = HOSTMexxar;
const DocView = (props) => {
  const { auth } = props;
  const windowHeight = window.innerHeight;

  const MexxarApiHost = HOSTMexxar;
  const MexxarApiFileUpload = Add_New_DOCUMENT;
  const MexxarApiCandidate = REGISTER_CANDIDATE;

  const { addToast } = useToasts();

  const isMounted = useIsMounted();

  const [expand, setExpand] = useState(false);
  const [document, setDocument] = useState([]);
  const [expDate, setExpDate] = useState(null);
  const [currentDoc, setCurrentDoc] = useState();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [issueDate, setIssueDate] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const [rejectReason, setRejectReason] = useState();
  const [combinedFLC, setCombinedFLC] = useState([]);
  const [documentName, setDocumentName] = useState(1);
  const [noDateNeed, setNoDateNeed] = useState(false);
  const [incomingData, setIncomingData] = useState(null);
  const [dateDifference, setDateDifference] = useState(null);
  const [expDateIsClose, setExpDateIsClose] = useState(false);
  const [showReasonInput, setShowReasonInput] = useState(false);
  const [documentUploaded, setDocumentUploaded] = useState(false);
  const [fileUploadedOrNot, setFileUploadedOrNot] = useState(false);
  const [candidateServiceType, setCandidateServiceType] = useState(null);
  const [archivedDocumentsUrls, setArchivedDocumentsUrls] = useState([]);
  const [selectedArchivedDocuemnt, setSelectedArchivedDocuemnt] = useState();
  const [selectedArchivedDocuemntType, setSelectedArchivedDocuemntType] =
    useState();
  const [showAssignConsultantModal, setShowAssignConsultantModal] =
    useState(false);
  const [
    openSelectedArchivedDocumentModal,
    setOpenSelectedArchivedDocumentModal,
  ] = useState(false);

  const handleExpand = () => {
    setExpand(!expand);
  };

  const checkPermissions = (permission) => {
    let array = filter(auth.item.userRole.permissions, (item) => {
      return item.name == permission;
    });
    return array;
  };
  const [filesAndDocs, setFilesAndDocs] = useState([
    {
      type: "image",
      documentName: "Sample 1",
      fileUrl: "http://207.246.85.110/docs/certificate_1.jpg",
    },
  ]);

  //Extract framework and level data for each document name ,send to docview page summery details
  //Document level Badge and Framework Badge
  const getFLtoDocView = () => {
    let tempDocId = filesAndDocs[currentDoc]?.documentId;
    axios
      .get(
        HOSTMexxar +
          "documents/combine-docs/filter?roleId=" +
          Number(props.match.params.candidateRole) +
          "&documentId=" +
          tempDocId
      )
      .then((res) => {
        let B = filter(res.data.body.content, (item) => {
          return (
            item.candidateSpeciality.id ===
            Number(props.match.params.changedSpecility)
          );
        });

        let FLCombined = [];

        forEach(B, function (item) {
          FLCombined.push({
            framework: item.framework.name,
            level: item.level.name,
          });
        });

        setCombinedFLC(FLCombined);
      })
      .catch((err) => {});
  };

  const handleLeft = () => {
    setSelectedDocuments([]);
    if (currentDoc != 0) {
      setIncomingData(null);
      setCurrentDoc(currentDoc - 1);
      setIssueDate(filesAndDocs[currentDoc - 1].issueDate);
      setExpDate(filesAndDocs[currentDoc - 1].expireDate);

      setDocumentName(filesAndDocs[currentDoc - 1].documentId);
      setPageNumber(1);
    }
  };

  const handleRight = () => {
    setSelectedDocuments([]);
    if (filesAndDocs.length - 1 > currentDoc) {
      setIncomingData(null);
      setCurrentDoc(currentDoc + 1);
      setIssueDate(filesAndDocs[currentDoc + 1].issueDate);
      setExpDate(filesAndDocs[currentDoc + 1].expireDate);

      setDocumentName(filesAndDocs[currentDoc + 1].documentId);
      setPageNumber(1);
    }
    // else {
    //   setIssueDate(null);
    //   setExpDate(null);
    // }
  };
  const checkExpDateRequried = () => {
    let expDate = filesAndDocs[currentDoc].expireDate;
    let issueDate = filesAndDocs[currentDoc].issueDate;

    let diffDays = moment
      .duration(moment(expDate).diff(moment(new Date())))
      .asDays();

    if (0 < diffDays && diffDays <= 30) {
      setExpDateIsClose(true);
    } else {
      setExpDateIsClose(false);
    }

    if (expDate != null || expDate != undefined) {
      setIssueDate(moment(issueDate).format("YYYY/MM/DD"));
      setExpDate(moment(expDate).format("YYYY/MM/DD"));
    }

    if (
      filesAndDocs[currentDoc].expireDate === "2500-01-01T00:00:00.000+00:00" ||
      filesAndDocs[currentDoc].expireDate === "2500-01-01"
    ) {
      setNoDateNeed(true);
    } else {
      setNoDateNeed(false);
    }
  };

  const [showRejectModal, setShowRejectModal] = useState(false);

  const toggleR = () => {
    if (showRejectModal) {
      getAllDocuments();
    }
    setShowRejectModal(!showRejectModal); // Toggle the modal's visibility
  };

  const toggle = () => setShowReasonInput(!showReasonInput);

  const changeDocument = (selectedOption) => {
    setDocumentName(selectedOption.value);

    const index = filesAndDocs.findIndex(
      (item) => item.documentId === selectedOption.value
    );

    setCurrentDoc(index);
  };

  const filteredOptions = filesAndDocs.filter(
    (item) => item.documentId !== filesAndDocs[currentDoc]?.documentId
  );

  const [documentLink, setDocumentLink] = useState("");
  const getAllPermDocuments = () => {
    axios
      .get(
        HOSTMexxar +
          "permanent/permDocs/status/" +
          props.match.params.candidateId
      )
      .then((res) => {
        let documentNameList = [];
        let reArrangedFilesAndDocs = [];
        forEach(res.data.body, function (item) {
          documentNameList.push({
            id: item.document.id,
            name: item.document.name,
          });

          reArrangedFilesAndDocs.push({
            archivedDocs: item.archivedDocs,
            id: item.document.id,
            auditedById: 1,
            documentApprovedStatus: item.documentApprovedStatus,
            auditedByName: "Mexxar Admin", //<<<<< Because in perm docs no auditing is done.
            documentName: item.document.name,
            documentId: item.document.id,
            documentLink: item.documentLink,
            expireDate: item.expireDate,
            issueDate: item.issueDate,
            uploadedDate: item.uploadDate,
            candidateFirstName: "",
            candidateId: item.candidate_Id,
            candidateLastName: "",
            documentStatus: item.documentStatus,
          });
          setDocumentLink(item.documentLink);
        });
        setFilesAndDocs(reArrangedFilesAndDocs);

        setDocument(documentNameList);
      })
      .catch((err) => {
        // console.log(err);
        // toastCommon(true, "error");
      });
  };

  //Candidate's document list according to the framework ,role,speciality
  const [error, setError] = useState(false);

  const getAllDocuments = () => {
    setError(false);
    axios
      .get(
        MexxarApiCandidate +
          "/" +
          props.match.params.candidateId +
          "/documents/framework/" +
          props.match.params.changedFramework +
          "/role/" +
          props.match.params.candidateRole +
          "/speciality/" +
          props.match.params.changedSpecility
      )
      .then((res) => {
        if (res.data.status === "success") {
          setFilesAndDocs(res.data.body);
          let documentNameList = [];
          forEach(res.data.body, function (item) {
            documentNameList.push({
              id: item.documentId,
              name: item.documentName,
            });
          });

          setDocument(documentNameList);
        }
      })
      .catch((err) => {
        setError(true);
      })
      .finally(() => {});
  };

  const pdfNextPage = () => {
    if (numPages > pageNumber) {
      setPageNumber(pageNumber + 1);
    }
  };

  const pdfPreviousPage = () => {
    if (pageNumber > 0) {
      setPageNumber(pageNumber - 1);
    }
  };

  const getIssueDate = (e) => {
    setIssueDate(e);
  };

  const getExpDate = (e) => {
    setExpDate(e);
  };

  //************confirm reject reason and function for heahunting/registration/maintenance consulant ****************//

  const ConfirmRejectReasonDocument = () => {
    const rejectreasonTemp = new FormData();

    rejectreasonTemp.append("status", "DECLINED");
    rejectreasonTemp.append("candidateId", props.match.params.candidateId);
    rejectreasonTemp.append("reason", rejectReason);

    axios
      .put(
        MexxarApiFileUpload + "/" + documentName + "/upload/confirm-status",
        rejectreasonTemp
      )
      .then((res) => {
        ToastCommon(true, "successfullyConfirmed", addToast);
        getAllDocuments();

        toggle();
      })
      .catch((err) => {});
  };

  //************Audit reject reason and function for audit consulant ****************//

  const AuditRejectReasonDocument = () => {
    const rejectreasonTemp = new FormData();

    rejectreasonTemp.append("status", "DECLINED");
    rejectreasonTemp.append("candidateId", props.match.params.candidateId);
    rejectreasonTemp.append("reason", rejectReason);

    axios
      .put(
        MexxarApiFileUpload + "/" + documentName + "/upload/audit-status",
        rejectreasonTemp
      )
      .then((res) => {
        if (res.data.status == "success") {
          ToastCommon(true, "successfullyVerified", addToast);
          getAllDocuments();
          toggle();
        } else {
          ToastCommon(true, "error", addToast);
        }
      })
      .catch((err) => {
        //console.log(err)
        ToastCommon(true, "error", addToast);
      });
  };

  const [uploading, setUploading] = useState(false);
  const [isConfirmAndVerify, setIsConfirmAndVerify] = useState(false);

  const handleSubmits = async (dateDiff) => {
    setUploading(true);

    let issueTempDate, expTempDate;
    if (noDateNeed) {
      issueTempDate = moment("1900-01-01");
      expTempDate = moment("2500-01-01");
    } else {
      issueTempDate = issueDate;
      expTempDate = expDate;
    }

    const fd = new FormData();

    if (incomingData) {
      fd.append("file", incomingData);
    }

    fd.append("candidateId", props.match.params.candidateId);
    fd.append("expiredDate", noDateNeed ? "2500/01/01" : String(expDate));
    fd.append("issuedDate", noDateNeed ? "1900/01/01" : String(issueDate));

    const documentPresent =
      incomingData != null || filesAndDocs[currentDoc].documentLink != null;

    const isValidDate =
      dateDiff >= 0 &&
      moment(expTempDate).isAfter(moment(new Date()).format("YYYY/MM/DD")) &&
      moment(issueTempDate).isSameOrBefore(
        moment(new Date()).format("YYYY/MM/DD")
      ) &&
      documentPresent;

    const documentIds = selectedDocuments.map((doc) => doc.id);

    try {
      if (isValidDate) {
        const baseURL =
          candidateServiceType === "TEMPORARY"
            ? `${MexxarApiFileUpload}/${documentName}/upload`
            : `${HOSTMexxar}permanent/permDocs/${documentName}/upload`;

        const uploadResponse = await axios.post(baseURL, fd);

        if (uploadResponse.data.status === "success") {
          const uploadedLink = uploadResponse.data.body[0].documentLink || "";
          const cleanedUploadedLink = uploadedLink.replace(
            "docs/certificates/",
            ""
          );

          if (isOpenFilter) {
            const uploadToMultipleData = {
              uploadedLink: cleanedUploadedLink,
              candidateId: props.match.params.candidateId,
              expiredDate: noDateNeed ? "2500/01/01" : String(expDate),
              issuedDate: noDateNeed ? "1900/01/01" : String(issueDate),
              documentIds: documentIds,
            };

            await axios.post(
              `${HOSTMexxar}documents/uploadToMultiple`,
              uploadToMultipleData
            );
          }

          if (!incomingData) {
            dateUpdatedSuccess(true);
          } else {
            ToastCommon(true, "success", addToast);
          }

          if (candidateServiceType === "TEMPORARY") {
            getAllDocuments();
          } else if (candidateServiceType === "PERMANENT") {
            getAllPermDocuments();
          }

          setSelectedDocuments([]);
          setDocumentUploaded(!documentUploaded);
          setIncomingData(null);
          getAllDocuments();

          let isConfirm = false;
          let isVerifyNConfirm = false;

          if (
            ((checkPermissions("HEADHUNTER").length > 0 ||
              checkPermissions("REGISTRATION").length > 0) &&
              (workFlowStatus === "HEADHUNTER" ||
                workFlowStatus === "REGISTRATION")) ||
            candidateData?.auditStatus === "DECLINE"
          ) {
            isConfirm = true;
            isVerifyNConfirm = false;
          }

          if (
            checkPermissions("MAINTENANCE_TEMP").length > 0 &&
            workFlowStatus === "MAINTENANCE"
          ) {
            isConfirm = true;
            isVerifyNConfirm = true;
          }

          if (
            checkPermissions("AUDIT_TEMP").length > 0 &&
            workFlowStatus === "AUDIT" &&
            candidateServiceType === "TEMPORARY"
          ) {
            isConfirm = false;
            isVerifyNConfirm = false;
          }

          if (isConfirmAndVerify) {
            const confirmationData = {
              documentIds: [...documentIds, documentName],
              candidateId: props.match.params.candidateId,
              status: "APPROVED",
              isConfirm,
              isVerifyNConfirm,
              reason: "N/A",
            };

            const confirmResponse = await axios.put(
              `${HOSTMexxar}documents/uploads/multiple-docs-status-update`,
              // "https://devapi.mexxar.com/documents/uploads/multiple-docs-status-update",
              confirmationData
            );
          }
          getAllDocuments();
          setArchivedDocumentsUrls([]);
          getArchivedDocs();
        } else {
          ToastCommon(true, "error", addToast);
          dateUpdatedSuccess(false);
        }
      } else {
        ToastCommon(true, "dateIssueOrFile", addToast);
      }
    } catch (err) {
      console.error(
        "Upload failed:",
        err.response ? err.response.data : err.message
      );
      if (err.response && err.response.status === 400) {
        ToastCommon(true, "documentUploadError", addToast);
      } else {
        ToastCommon(true, "error", addToast);
      }
    } finally {
      setUploading(false);
    }
  };

  const [selectedDocuments, setSelectedDocuments] = useState([]);

  const handleDocumentSelect = (selectedOptions) => {
    if (selectedOptions && selectedOptions.length > 0) {
      const newDocuments = selectedOptions.map((option) => ({
        id: option.value,
        name: option.label,
      }));
      setSelectedDocuments(newDocuments);
    } else {
      setSelectedDocuments([]);
    }
  };

  //***********only date is updated.not the file **************/
  function dateUpdatedSuccess(status) {
    if (
      isNull(incomingData) &&
      filesAndDocs[currentDoc].documentLink != null &&
      status
    ) {
      ToastCommon(true, "dateUpdated", addToast);
    } else {
      ToastCommon(true, "dateIssue", addToast);
    }
  }

  //*********confirming document by heahunter/maintenance/registration/ consultant**************//

  const handleConfirmDocument = () => {
    return new Promise((resolve, reject) => {
      const confirmTemp = new FormData();

      confirmTemp.append("status", "APPROVED");
      confirmTemp.append("candidateId", props.match.params.candidateId);
      confirmTemp.append("reason", "");

      axios
        .put(
          MexxarApiFileUpload + "/" + documentName + "/upload/confirm-status",
          confirmTemp
        )
        .then((res) => {
          if (res.data.status == "success") {
            ToastCommon(true, "successfullyConfirmed", addToast);
            getAllDocuments();
            resolve(); // resolve the Promise if the request was successful
          } else {
            ToastCommon(true, "error", addToast);
            reject(); // reject the Promise if the request was not successful
          }
        })
        .catch((err) => {
          //console.log(err)
          ToastCommon(true, "error", addToast);
          reject(); // reject the Promise if there was an error
        });
    });
  };

  const handleRejectPermDoc = () => {
    axios
      .post(
        HOSTMexxar +
          "permanent/candidates/" +
          props.match.params.candidateId +
          "/document/" +
          documentName +
          "?documentStatus=" +
          1,
        { reason: rejectReason } //DECLINE
      )
      .then((res) => {
        if (res.data.status == "success") {
          ToastCommon(true, "successfullyVerified", addToast);
          getAllPermDocuments();
          toggle();
        } else {
          ToastCommon(true, "error", addToast);
        }
      })
      .catch((error) => {
        ToastCommon(true, "error", addToast);
      });
  };

  const handlePermVerify = () => {
    axios
      .post(
        HOSTMexxar +
          "permanent/candidates/" +
          props.match.params.candidateId +
          "/document/" +
          documentName +
          "?documentStatus=" +
          2 //APPROVE
      )
      .then((res) => {
        if (res.data.status == "success") {
          ToastCommon(true, "successfullyVerified", addToast);
          getAllPermDocuments();
        } else {
          ToastCommon(true, "error", addToast);
        }
      })
      .catch((error) => {
        ToastCommon(true, "error", addToast);
      });
  };

  //*********verifing document by audit/maintenance consultant**************//
  const handleVerify = () => {
    return new Promise((resolve, reject) => {
      const verifiedTemp = new FormData();

      verifiedTemp.append("status", "APPROVED");
      verifiedTemp.append("candidateId", props.match.params.candidateId);
      verifiedTemp.append("reason", "");
      axios
        .all([
          axios.put(
            MexxarApiFileUpload +
              "/" +
              filesAndDocs[currentDoc].documentId +
              "/upload/audit-status",
            verifiedTemp
          ),
          axios.put(
            MexxarApiHost +
              "candidates/" +
              props.match.params.candidateId +
              "/audit/CONFIRM"
          ),
        ])
        .then(
          axios.spread((res, res2) => {
            if (res.data.status == "success" && res2.data.status == "success") {
              ToastCommon(true, "successfullyVerified", addToast);
              getAllDocuments();
              resolve(); // resolve the Promise if the request was successful
            } else {
              ToastCommon(true, "error", addToast);
              reject(); // reject the Promise if the request was not successful
            }
          })
        )
        .catch((err) => {
          //console.log(err)
          ToastCommon(true, "error", addToast);
          reject(); // reject the Promise if there was an error
        });
    });
  };

  //Perm Doc Verification Step
  const handleConfirmVerify = () => {
    if (candidateServiceType == "TEMPORARY") {
      // handleConfirmDocument();
      // handleVerify();
      handleConfirmDocument()
        .then(handleVerify)
        .catch((error) => {
          ToastCommon(true, "error", addToast);
        });
    }
    if (candidateServiceType == "PERMANENT") {
      handlePermVerify();
    }
  };

  /**************** DateDifferenceCheck****************/
  const dDC = () => {
    /*check if date is required for the document or not*/
    if (noDateNeed == true) {
      //appending upload file and data related to new form
      // setDateDifference();
      handleSubmits(10000);
    } else {
      // diffDays = moment
      //   .duration(moment(expDate).diff(moment(new Date())))
      //   .asDays();

      let diffDays = Math.floor(
        (moment(expDate).utc() - moment(new Date())) / 86400e3
      );

      /*expire date is close badge visibility*/

      if (0 < diffDays && diffDays <= 30) {
        setExpDateIsClose({
          bool: true,
          colorCode: "#ff9800",
          symbol: "expire date is close",
        });
      } else if (diffDays < 0) {
        setExpDateIsClose({
          bool: true,
          colorCode: "#dc3545",
          symbol: "document expired",
        });
      }

      setDateDifference(diffDays);
      handleSubmits(diffDays);
    }
  };

  const [workFlowStatus, setWorkFlowStatus] = useState("");
  const [candidateData, setCandidateData] = useState(null);

  const getCandidateWorkflowStatus = () => {
    axios
      .get(MexxarApiCandidate + "/" + props.match.params.candidateId)
      .then((res) => {
        if (res.data.status == "success") {
          setWorkFlowStatus(res.data.body[0].workFlowStatus);
          setCandidateData(res.data.body[0]);
        } else {
          ToastCommon(true, "error", addToast);
        }
      })
      .catch((err) => {
        //console.log(err)
        // toastCommon(true, "error");
      });
  };

  const sendToAudit = (data) => {
    axios
      .put(
        MexxarApiCandidate +
          "/" +
          props.match.params.candidateId +
          "/workflow/AUDIT"
      )

      .then((res) => {
        if (res.data.status == "success") {
          ToastCommon(true, "workflowUpdated", addToast);
          toggleR();
        } else {
          ToastCommon(true, "error", addToast);
        }
      })

      .catch((err) => {
        //console.log(err)
        //ToastCommon(true, "error", addToast);
      });
  };

  const sendBackToRegistration = (data) => {
    axios
      .put(
        MexxarApiCandidate +
          "/" +
          props.match.params.candidateId +
          "/workflow/AUDIT"
      )
      .then((res) => {
        if (res.data.status == "success") {
          auditDeclining();
        } else {
          ToastCommon(true, "error", addToast);
        }
      })
      .catch((err) => {
        //console.log(err)
        ToastCommon(true, "error", addToast);
      });
  };

  const auditDeclining = (data) => {
    axios
      .put(
        MexxarApiHost +
          "candidates/" +
          props.match.params.candidateId +
          "/audit/DECLINE"
      )
      .then((res) => {
        if (res.data.status == "success") {
          ToastCommon(true, "workflowUpdated", addToast);
          toggleR();
        } else {
          ToastCommon(true, "error", addToast);
        }
      })
      .catch((err) => {
        //console.log(err)
        ToastCommon(true, "error", addToast);
      });
  };

  const sendToMaintenance = (data) => {
    let consultantIds = [];
    if (data != null) {
      forEach(data, function (item) {
        consultantIds.push(item.id);
      });
    }

    axios
      .all([
        axios.put(
          MexxarApiCandidate +
            "/" +
            props.match.params.candidateId +
            "/add-employees?role=MAINTENANCE",
          consultantIds
        ),
        axios.put(
          MexxarApiCandidate +
            "/" +
            props.match.params.candidateId +
            "/add-employees?role=BOOKING",
          consultantIds
        ),
        axios.put(
          MexxarApiCandidate +
            "/" +
            props.match.params.candidateId +
            "/workflow/MAINTENANCE"
        ),
        axios.put(
          MexxarApiHost +
            "candidates/" +
            props.match.params.candidateId +
            "/audit/CONFIRM"
        ),
      ])
      .then(
        axios.spread((res1, res2, res3) => {
          if (
            res1.data.status == "success" &&
            res2.data.status == "success" &&
            res3.data.status == "success"
          ) {
            ToastCommon(true, "success", addToast);

            setShowAssignConsultantModal(false);
          } else {
            ToastCommon(true, "error", addToast);
          }
        })
      )
      .catch((err) => {});
  };

  const showArchivedDocuments = () => {
    setOpenModal(true);
  };

  const recordRejectReason = () => {
    if (candidateServiceType == "PERMANENT") {
      handleRejectPermDoc();
    }
    if (candidateServiceType == "TEMPORARY") {
      if (
        checkPermissions("HEADHUNTER_TEMP").length > 0 ||
        checkPermissions("REGISTRATION_TEMP").length > 0 ||
        checkPermissions("MAINTENANCE_TEMP").length > 0
      ) {
        ConfirmRejectReasonDocument();
      } else if (
        checkPermissions("AUDIT_TEMP").length > 0 ||
        checkPermissions("MAINTENANCE_TEMP").length > 0
      ) {
        AuditRejectReasonDocument();
      }
    }
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const onSelect = (index) => {
    if (candidateServiceType == "TEMPORARY") {
      let url =
        MexxarAPI +
        "docs/certificates/" +
        split(index.docLink, "/")[2] +
        "/?documents=ARCHIVE";
      setSelectedArchivedDocuemnt(url);
    }
    if (candidateServiceType == "PERMANENT") {
      setSelectedArchivedDocuemnt(
        MexxarAPI +
          "permanent/docs/permanent/" +
          split(index.docLink, "/")[3] +
          "?documents=ARCHIVE"
      );
    }

    setSelectedArchivedDocuemntType(split(index.docLink, ".")[1]);
    setOpenSelectedArchivedDocumentModal(true);
  };

  const deleteDoc = (doc) => {
    axios
      .delete(`${MexxarAPI}documents/${doc.id}/candidate/${doc.candidateId}`)
      .then((res) => {
        if (res.data.status == "success" || res.data.message == "created") {
          ToastCommon(true, "documentDeleted", addToast);
          getAllDocuments();
        } else {
          ToastCommon(true, "error", addToast);
        }
      })
      .catch((err) => {
        //ToastCommon(true, "error", addToast);
      });
  };

  useDidMountEffect(() => {
    let index = findIndex(filesAndDocs, (item) => {
      return item.documentId == Number(props.match.params.docId);
    });
    setCurrentDoc(index);
  }, [document]);
  // useDidMountEffect(() => {
  //   handleSubmits();
  // }, [dateDifference]);

  useDidMountEffect(() => {
    checkExpDateRequried();
    return () => {};
  }, [currentDoc]);

  useEffect(() => {
    getArchivedDocs();
    getFLtoDocView();
    return () => {};
  }, [currentDoc]);

  useEffect(() => {
    // getDocuments();
    getCandidateWorkflowStatus();
    //  setCurrentDoc(Number(props.match.params.docId));
    let candidateServiceType = localStorage.getItem("candidateServiceType");
    setCandidateServiceType(candidateServiceType);

    if (candidateServiceType == "PERMANENT") {
      getAllPermDocuments();
    } else if (candidateServiceType == "TEMPORARY") {
      getAllDocuments();
    }

    setDocumentName(Number(props.match.params.docId));

    return () => {};
  }, []);

  const [docsLoading, setDocsLoading] = useState({
    status: true,
    type: "",
    docType: "",
  });
  //prepare document links before download the documents through below function
  const downloadArchiveDocs = (data) => {
    let temp = [];
    let i = 0;
    forEach(data, function (doc) {
      let expDate = moment(doc.expiredDate).format("YYYY_MM_DD");
      temp.push({
        documentLink: doc?.docLink,
        certificateName:
          filesAndDocs[currentDoc]?.documentName + "_" + expDate + "_" + i,
      });
      i++;
    });

    downloadDocs(
      {
        doc: temp,
        candidateId: filesAndDocs[currentDoc]?.candidateId,
        candidateServiceType: candidateServiceType,
        candidateName:
          filesAndDocs[currentDoc]?.candidateFirstName +
          " " +
          filesAndDocs[currentDoc]?.candidateLastNam,
        type: "MULTIPLE",
        docType: "ARCHIVE",
        setDocsLoading,
      },
      abortControllerRef.current
    );
  };

  const copyDocLink = (data) => {
    copyToClipboard(data);
    ToastCommon(true, "coppied", addToast);
  };

  const imageExtensions = [".jpeg", ".jpg", ".png"];

  // Function to check if the URL has a valid image extension
  const hasImageExtension = (url) => {
    const lowerCaseUrl = url.toLowerCase();

    return imageExtensions.some((extension) =>
      lowerCaseUrl.endsWith(extension)
    );
  };

  const [trustIdInfo, setTrustIdInfo] = useState({ trustIdStatus: "NOT_SENT" });
  const [exportTrustIDPDF, setExportTrustIDPDF] = useState(null);
  const [errorTrustIdPDF, setErrorTrustIdPDF] = useState(false);

  const getTrustIdStatus = () => {
    axios
      .get(
        `${MexxarAPI}complianceCheck/trustId/candidate/${props.match.params.candidateId}`
      )
      .then((res) => {
        let selectedDoc = res.data?.body?.find((doc) =>
          filesAndDocs[currentDoc]?.documentName
            .toLowerCase()
            .includes(doc.documentName.toLowerCase())
        );

        if (selectedDoc) {
          setTrustIdInfo(selectedDoc);
        } else {
        }
      })
      .catch((error) => {});
  };

  //change the trustID check status as valid.
  //This is a manual process since consultant need to double check the results sending from trust ID check and merge/upload the original document to the candidate again
  useDidMountEffect(() => {
    getTrustIdStatus();
  }, [filesAndDocs[currentDoc]?.documentName]);

  useEffect(() => {
    getTrustIdStatus();
    return () => {};
  }, []);
  const abortControllerRef = useRef(new AbortController());
  const deleteArchiveDoc = (docId) => {
    let remove = archivedDocumentsUrls.findIndex((doc) => doc.id === docId);
    archivedDocumentsUrls.splice(remove, 1);
    axios
      .delete(
        `${MexxarAPI}documents/deleteArchiveDocById?archivedDocId=${docId}`
      )
      .then((res) => {
        if (res.status === 200) {
          ToastCommon(true, "delete", addToast);

          getAllDocuments();
        }
      })
      .catch((error) => {});
  };

  const handleDownloadClick = () => {
    abortControllerRef.current = new AbortController();

    downloadDocs(
      {
        doc: [
          {
            documentLink: filesAndDocs[currentDoc]?.documentLink,
            certificateName: filesAndDocs[currentDoc]?.documentName,
          },
        ],
        candidateId: filesAndDocs[currentDoc]?.candidateId,
        candidateServiceType: candidateServiceType,
        candidateName:
          filesAndDocs[currentDoc]?.candidateFirstName +
          " " +
          filesAndDocs[currentDoc]?.candidateLastName,
        type: "SINGLE",
        docType: "DEFAULT",
        setDocsLoading,
      },
      abortControllerRef.current
    );
  };
  const handleCancelClick = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort(); // This will cancel the fetch request
    }
    setDocsLoading({ status: false, type: "", docType: "" });
  };
  const cancelDownload = () => {
    abortControllerRef.current.abort();
    setDocsLoading({ status: false, type: "", docType: "" });
  };

  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const toggleFilter = () => setIsOpenFilter(!isOpenFilter);
  const [showDeleteDocument, setShowDeleteDocument] = useState(false);

  const history = useHistory();

  const getArchivedDocs = () => {
    axios
      .get(
        MexxarApiHost +
          "/documents/getArchivedDocs?candidateId=" +
          props.match.params.candidateId +
          "&documentId=" +
          filesAndDocs[currentDoc]?.documentId
      )
      .then((archivedDocs) => {
        let tempArray = [];
        if (
          archivedDocs.data.body !== undefined &&
          archivedDocs.data.body != null
        ) {
          for (let i = 0; i < archivedDocs.data.body.length; i++) {
            tempArray.push(archivedDocs.data.body[i]);
          }
        }
        setArchivedDocumentsUrls(tempArray);
      });
  };

  const handleDeleteDocument = () => {
    axios
      .request({
        url:
          HOSTMexxar +
          "documents/deleteDocsByLink?link=" +
          filesAndDocs[currentDoc]?.documentLink,
        method: "DELETE",
      })
      .then((res) => {
        ToastCommon(true, "delete", addToast);
        getAllDocuments();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div id="main" className="layout-column flex ">
      <div
        id="header"
        className="d-flex justify-content-between page-header bg-body sticky"
        data-classname="bg-body"
      >
        <div className="navbar navbar-expand-lg">
          <Link
            to={{
              pathname:
                "/dashboard" +
                "/candidate-profile/" +
                props.match.params.candidateId +
                "/+4545454545" +
                "/compliance",
            }}
          >
            <span className="btn btn-sm btn-icon btn-rounded btn-raised  bg-dark-overlay ">
              <FontAwesomeIcon
                className="feather feather-more-vertical text-fade "
                icon={faChevronLeft}
              />
            </span>
          </Link>

          <small className="ml-2"> Go Back</small>
        </div>

        <Header></Header>
      </div>
      {isNaN(currentDoc) ? (
        <div
          className="d-flex  align-items-center justify-content-center"
          style={{ height: windowHeight }}
        >
          <div> Please go back to compliance page and try again</div>
        </div>
      ) : (
        <div className="padding">
          <div className="flex ">
            <div className="row">
              <div
                className={`col-12 col-sm-12 col-md-12 ${
                  expand ? "col-lg-12" : "col-lg-8"
                }`}
              >
                <div className="card minMaxHeight">
                  <div className="card-heading text-center p-2 mt-2 d-flex justify-content-between">
                    <div className="">{}</div>
                    <div>{filesAndDocs[currentDoc]?.documentName}</div>
                    <div className="mx-3" onClick={() => handleExpand()}>
                      <a className="btn btn-sm btn-icon bg-dark-overlay btn-rounded">
                        <FontAwesomeIcon
                          className="feather feather-more-vertical text-fade"
                          icon={faExpandAlt}
                        />
                      </a>
                    </div>
                  </div>

                  <div className="card-body">
                    <div className="row ">
                      <div className="col-1 col-sm-1 col-md-1  align-items-center d-flex">
                        <div
                          className="navbar navbar-expand-lg"
                          onClick={() => handleLeft()}
                          style={{ width: "63px" }}
                        >
                          <a
                            className={`btn btn-sm btn-icon btn-rounded  ${
                              currentDoc != 0
                                ? "btn-raised bg-dark-overlay "
                                : null
                            } `}
                          >
                            <FontAwesomeIcon
                              className="feather feather-more-vertical text-fade"
                              icon={faChevronLeft}
                            />
                          </a>
                        </div>
                      </div>
                      <div className="col-10 col-sm-10 col-md-10">
                        <SingleFileAutoSubmit
                          candidateServiceType={candidateServiceType}
                          deleteDoc={(doc) => deleteDoc(doc)}
                          expandStatus={expand}
                          documentUploaded={documentUploaded}
                          doc={filesAndDocs[currentDoc]}
                          documentName={documentName}
                          issueDate={issueDate}
                          expDate={expDate}
                          setIncomingData={setIncomingData}
                          incomingData={incomingData}
                          fileUploadedOrNot={(data) =>
                            setFileUploadedOrNot(data)
                          }
                        />
                      </div>

                      <div className="col-1 col-sm-1 col-md-1 align-items-center d-flex  ">
                        <div
                          className="navbar navbar-expand-lg"
                          onClick={() => handleRight()}
                          style={{ width: "63px" }}
                        >
                          <a
                            className={`btn btn-sm btn-icon btn-rounded  ${
                              filesAndDocs.length - 1 > currentDoc
                                ? "btn-raised  bg-dark-overlay "
                                : null
                            } `}
                          >
                            <FontAwesomeIcon
                              className="feather feather-more-vertical text-fade"
                              icon={faChevronRight}
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {expand ? null : (
                <div
                  className={`col-12 col-sm-12 col-md-12 ${
                    expand ? null : "col-lg-4"
                  } `}
                >
                  <div className="card" style={{ height: "fit-content" }}>
                    <div className="card-body">
                      <div className="docViewRight">
                        <FormGroup row>
                          <Col sm={12} md={12} lg={12}>
                            <Label for="candidateName">
                              {candidateData != null
                                ? candidateData.firstName
                                : ""}
                              &nbsp;
                              {candidateData != null
                                ? candidateData.lastName
                                : ""}
                              {/* {filesAndDocs[currentDoc].candidateFirstName}{" "}
                              {filesAndDocs[currentDoc].candidateLastNam}{" "} */}
                              {checkPermissions("EMPLOYEE").length > 0 ? (
                                candidateData != null ? (
                                  <Link
                                    className="btn btn-sm btn-icon bg-dark-overlay btn-rounded mx-3"
                                    to={"/dashboard/call/" + candidateData.id}
                                  >
                                    <FontAwesomeIcon
                                      icon={faPhone}
                                      className="feather feather-phone text-fade"
                                    ></FontAwesomeIcon>
                                  </Link>
                                ) : null
                              ) : null}
                            </Label>
                            {archivedDocumentsUrls.length > 0 && (
                              <div>
                                <Button
                                  className=""
                                  color="primary"
                                  onClick={showArchivedDocuments}
                                >
                                  Archived Documents
                                </Button>
                              </div>
                            )}
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Col xs={12} sm={10} md={10}>
                            <Label for="documentId">
                              Document Name/Qualification
                            </Label>
                            <FontAwesomeIcon
                              id="documentLink"
                              className="ml-2 pointer"
                              icon={faCopy}
                              onClick={() =>
                                copyDocLink(
                                  filesAndDocs[currentDoc]?.documentLink
                                )
                              }
                            />
                            <UncontrolledTooltip target={"documentLink"}>
                              Copy document link
                            </UncontrolledTooltip>

                            <Select
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                  color: "#333333",
                                }),
                                option: (base) => ({
                                  ...base,
                                  color: "black",
                                }),
                              }}
                              id="documentId"
                              value={{
                                value: filesAndDocs[currentDoc]?.documentId,
                                label: filesAndDocs[currentDoc]?.documentName,
                              }}
                              name="documentId"
                              className="basic-multi-select"
                              classNamePrefix="select"
                              onChange={changeDocument}
                              options={filesAndDocs.map((item) => ({
                                value: item.documentId,
                                label: item.documentName,
                              }))}
                            />
                          </Col>

                          {filesAndDocs[currentDoc]?.documentLink && (
                            <Col
                              xs={12}
                              sm={2}
                              md={2}
                              className="d-flex flex-column align-items-center  mb-2"
                            >
                              {checkPermissions("ADMIN").length > 0 && (
                                <Button
                                  onClick={() => setShowDeleteDocument(true)}
                                  color="danger"
                                >
                                  <FontAwesomeIcon icon={faTrash} />
                                </Button>
                              )}
                              <Button
                                onClick={handleDownloadClick}
                                color="success"
                                className="mt-2"
                              >
                                <FontAwesomeIcon icon={faFileDownload} />
                              </Button>
                            </Col>
                          )}
                        </FormGroup>
                        <ModalComponent
                          show={
                            docsLoading.status &&
                            docsLoading.type === "SINGLE" &&
                            docsLoading.docType === "DEFAULT"
                          }
                        >
                          <DocumentLoader
                            onCancel={handleCancelClick}
                            showCancel={true}
                          />
                        </ModalComponent>
                      </div>

                      {candidateServiceType == "TEMPORARY" && (
                        <>
                          <div className="form-row docViewRight">
                            <div className="col-6 col-sm-6 col-md-6  form-group">
                              <label className="text-muted ">
                                Document Level
                              </label>
                            </div>
                            <div className="col-6 col-sm-6 col-md-6 form-group">
                              <label className="text-muted"> Framework</label>
                            </div>
                          </div>
                          {combinedFLC?.map((item, id) => {
                            return (
                              <div className="form-row docViewRight" key={id}>
                                <div className="col-6 col-sm-6 col-md-6  form-group">
                                  <div>
                                    <span className="badge badge-success text-uppercase">
                                      {item.level}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-6 col-sm-6 col-md-6 form-group">
                                  <div>
                                    <span className="badge badge-warning text-uppercase">
                                      {item.framework}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      )}

                      <div>
                        <label className="ui-check m-0">
                          <Input
                            type="checkbox"
                            checked={noDateNeed}
                            onChange={() => setNoDateNeed(!noDateNeed)}
                          />
                          <i></i>
                        </label>
                        <Label
                          className="text-color text-sm mt-3"
                          id="dateIsNA"
                        >
                          Expire date is not applicable
                        </Label>

                        <UncontrolledTooltip
                          placement="right"
                          target="dateIsNA"
                        >
                          Check the box if date period is not necessary
                        </UncontrolledTooltip>
                      </div>

                      <div></div>

                      <br></br>

                      <div className="form-row docViewRight">
                        <div className="col-6 col-sm-6 col-md-6  form-group">
                          <label className="text-muted ">
                            Would you like to tag this document to other
                            documents as well?
                          </label>
                        </div>
                        <div className="col-6 col-sm-6 col-md-6 form-group">
                          <Button
                            onClick={toggleFilter}
                            className="btn bg-dark-lt text-dark"
                          >
                            <span className="mx-1">Upload to Multiple </span>
                          </Button>
                        </div>

                        {selectedDocuments.length > 0 && (
                          <div className="form-row docViewRight">
                            <div className="col-6 col-sm-6 col-md-6 form-group">
                              <label className="text-muted">
                                Would you like to confirm and verify this
                                document after upload?
                              </label>
                            </div>
                            <div className="col-6 col-sm-6 col-md-6 form-group">
                              <input
                                type="checkbox"
                                checked={isConfirmAndVerify}
                                onChange={(e) =>
                                  setIsConfirmAndVerify(e.target.checked)
                                }
                              />
                              <label className="mx-1">Confirm/Verify</label>
                            </div>
                          </div>
                        )}
                      </div>

                      <br />
                      <Collapse isOpen={isOpenFilter}>
                        <div>
                          <Select
                            isMulti
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={handleDocumentSelect}
                            value={selectedDocuments.map((item) => ({
                              value: item.id,
                              label: item.name,
                            }))}
                            options={filteredOptions.map((item) => ({
                              value: item.documentId,
                              label: item.documentName,
                            }))}
                          />
                        </div>

                        <br></br>

                        {selectedDocuments.map((doc, index) => (
                          <div key={index}>
                            <span
                              style={{ color: "white", fontWeight: "bold" }}
                            >
                              <FontAwesomeIcon
                                id="department"
                                className="text-success text-sm mx-1"
                                icon={faFileArchive}
                              ></FontAwesomeIcon>{" "}
                              {doc.name}
                            </span>
                            <br /> <br />
                          </div>
                        ))}
                      </Collapse>

                      {noDateNeed ? null : (
                        <div className="form-row docViewRight">
                          <div className="col-6 col-md-6 form-group">
                            <label className="text-muted">Issue Date</label>
                            {/* <Input
                              type="date"
                              max="9999-12-31"
                              value={moment(issueDate).format("YYYY-MM-DD")}
                              onChange={getIssueDate}
                              defaultValue={moment(
                                filesAndDocs[currentDoc].issueDate
                              ).format("YYYY-MM-DD")}
                            ></Input> */}

                            <DateInput
                              id="issueDate"
                              value={dateFormatter.formatDateToDDMMYYYYFROMYYYYMMDDSLASH(
                                issueDate
                              )}
                              onChange={(date) =>
                                getIssueDate(
                                  dateFormatter.formatDateToYYYYMMDDSLASHFROMDDMMYYYY(
                                    date
                                  )
                                )
                              }
                            ></DateInput>
                          </div>
                          <div className="col-6 col-md-6 form-group">
                            <label className="text-muted">Expire Date</label>

                            <DateInput
                              id="issueDate"
                              value={dateFormatter.formatDateToDDMMYYYYFROMYYYYMMDDSLASH(
                                expDate
                              )}
                              onChange={(date) =>
                                getExpDate(
                                  dateFormatter.formatDateToYYYYMMDDSLASHFROMDDMMYYYY(
                                    date
                                  )
                                )
                              }
                            ></DateInput>
                          </div>
                        </div>
                      )}

                      {expDateIsClose && noDateNeed == false ? (
                        <Badge color="danger">expire date is close</Badge>
                      ) : null}
                      <div className="form-row docViewRight-sub mt-3">
                        {candidateServiceType == "TEMPORARY" && (
                          <small className="col-3 col-md-3 text-muted ">
                            Confirmed By:
                          </small>
                        )}

                        <small className="col-3  col-md-3 text-muted ">
                          {filesAndDocs[currentDoc].confirmedByName === null
                            ? "------"
                            : filesAndDocs[currentDoc].confirmedByName}
                        </small>
                        <small className="col-3   col-md-3 text-muted ">
                          {/* {filesAndDocs[currentDoc].confirmedByName === null
                            ? "------"
                            : filesAndDocs[currentDoc].confirmedByName} */}
                        </small>

                        <div className="col-3   col-md-3 ">
                          <Badge
                            color={
                              ProfileStatus(
                                filesAndDocs[currentDoc].confirmation
                              ).badgeColor
                            }
                          >
                            {
                              ProfileStatus(
                                filesAndDocs[currentDoc].confirmation
                              ).badgeLable
                            }
                          </Badge>
                        </div>
                      </div>

                      <div className="form-row docViewRight-sub my-1">
                        <small className="col-3  col-md-3 text-muted  ">
                          Audited By:
                        </small>
                        <small className="col-3  col-md-3 text-muted ">
                          {filesAndDocs[currentDoc].auditedByName === null
                            ? "------"
                            : filesAndDocs[currentDoc].auditedByName}
                        </small>
                        <small className="col-3  col-md-3 text-muted ">
                          {/* ------ */}
                        </small>

                        <div className="col-3  col-md-3 text-muted ">
                          {candidateServiceType == "PERMANENT" && (
                            <Badge
                              color={
                                ProfileStatus(
                                  filesAndDocs[currentDoc]
                                    .documentApprovedStatus
                                ).badgeColor
                              }
                            >
                              {
                                ProfileStatus(
                                  filesAndDocs[currentDoc]
                                    .documentApprovedStatus
                                ).badgeLable
                              }
                            </Badge>
                          )}

                          {candidateServiceType == "TEMPORARY" && (
                            <Badge
                              color={
                                ProfileStatus(filesAndDocs[currentDoc].audition)
                                  .badgeColor
                              }
                            >
                              {
                                ProfileStatus(filesAndDocs[currentDoc].audition)
                                  .badgeLable
                              }
                            </Badge>
                          )}
                        </div>
                      </div>

                      <div className="form-row docViewRight-sub my-1">
                        {/* <small className="col-3  col-md-3 text-muted  ">
                        Mandatory or If applicable :
                        </small> */}

                        <div className="col-3  col-md-3 text-muted ">
                          {filesAndDocs[currentDoc]?.type == "MANDATORY" ? (
                            <Badge color="danger">Mandatory</Badge>
                          ) : null}
                          {filesAndDocs[currentDoc]?.type == "IF_APPLICABLE" ? (
                            <Badge color="warning">If Applicable </Badge>
                          ) : null}
                        </div>
                      </div>

                      <div className="form-row p-3" />
                      <div className="form-row docViewRight mt-1">
                        <div className="col-3.5 col-sm-4  col-md-12 ">
                          {/* //*************Upload button for candidate/heahunter /registration/maintenance consultants********** */}
                          {checkPermissions("CANDIDATE").length > 0 ||
                          checkPermissions("HEADHUNTER").length > 0 ||
                          checkPermissions("REGISTRATION").length > 0 ||
                          checkPermissions("MAINTENANCE").length > 0 ? (
                            <Link
                              to={{
                                pathname:
                                  "/dashboard/docview/" +
                                  "UP/" +
                                  props.match.params.candidateId +
                                  "/" +
                                  documentName +
                                  "/" +
                                  props.match.params.candidateRole +
                                  "/" +
                                  props.match.params.changedSpecility +
                                  "/" +
                                  props.match.params.changedFramework,
                              }}
                            >
                              <button
                                className="btn w-sm mb-1 btn-sm btn-success mr-2"
                                onClick={dDC}
                                disabled={uploading}
                              >
                                Upload
                              </button>
                            </Link>
                          ) : null}

                          {/* //*************Upload and confirm button for heahunter /registration consultants********** */}

                          {((checkPermissions("HEADHUNTER").length > 0 ||
                            checkPermissions("REGISTRATION").length > 0) &&
                            (workFlowStatus == "HEADHUNTER" ||
                              workFlowStatus == "REGISTRATION")) ||
                          candidateData?.auditStatus == "DECLINE" ? (
                            <Link
                              to={{
                                pathname:
                                  "/dashboard/docview/" +
                                  "UP/" +
                                  props.match.params.candidateId +
                                  "/" +
                                  documentName +
                                  "/" +
                                  props.match.params.candidateRole +
                                  "/" +
                                  props.match.params.changedSpecility +
                                  "/" +
                                  props.match.params.changedFramework,
                              }}
                            >
                              <button
                                className="btn w-sm mb-1 btn-sm btn-success mr-2"
                                onClick={handleConfirmDocument}
                                disabled={
                                  filesAndDocs[currentDoc].documentLink != null
                                    ? false
                                    : true
                                }
                              >
                                Confirm
                              </button>
                            </Link>
                          ) : null}

                          {/* //************* Confirm/Verify button for maintenance consultants********** */}

                          {checkPermissions("MAINTENANCE_TEMP").length > 0 &&
                          workFlowStatus == "MAINTENANCE" ? (
                            <Link
                              to={{
                                pathname:
                                  "/dashboard/docview/" +
                                  "UP/" +
                                  props.match.params.candidateId +
                                  "/" +
                                  documentName +
                                  "/" +
                                  props.match.params.candidateRole +
                                  "/" +
                                  props.match.params.changedSpecility +
                                  "/" +
                                  props.match.params.changedFramework,
                              }}
                            >
                              <button
                                className="btn w-sm mb-1 btn-sm btn-success mr-2"
                                onClick={() => {
                                  handleConfirmVerify();
                                }}
                                disabled={
                                  filesAndDocs[currentDoc].documentLink != null
                                    ? false
                                    : true
                                }
                              >
                                Confirm/Verify
                              </button>
                            </Link>
                          ) : null}

                          {/* //************* Verify button for audit consultants********** */}

                          {checkPermissions("AUDIT_TEMP").length > 0 &&
                            workFlowStatus == "AUDIT" &&
                            candidateServiceType == "TEMPORARY" && (
                              <Link
                                to={{
                                  pathname:
                                    "/dashboard/docview/" +
                                    "UP/" +
                                    props.match.params.candidateId +
                                    "/" +
                                    documentName +
                                    "/" +
                                    props.match.params.candidateRole +
                                    "/" +
                                    props.match.params.changedSpecility +
                                    "/" +
                                    props.match.params.changedFramework,
                                }}
                              >
                                <button
                                  className="btn w-sm mb-1 btn-sm btn-success mr-2"
                                  onClick={() => handleVerify()}
                                  disabled={
                                    filesAndDocs[currentDoc].documentLink !=
                                    null
                                      ? false
                                      : true
                                  }
                                >
                                  Verify
                                </button>
                              </Link>
                            )}
                        </div>

                        {/* //************* Decline button for Heahunting/Registration/Audit/MAintenance consultants********** */}

                        {checkPermissions("AUDIT").length > 0 ||
                        checkPermissions("HEADHUNTER").length > 0 ||
                        checkPermissions("REGISTRATION").length > 0 ||
                        checkPermissions("MAINTENANCE").length > 0 ? (
                          <div className="col-8.5  col-sm-8 col-md-12 ">
                            <Link
                              to={{
                                pathname:
                                  "/dashboard/docview/" +
                                  "UP/" +
                                  props.match.params.candidateId +
                                  "/" +
                                  documentName +
                                  "/" +
                                  props.match.params.candidateRole +
                                  "/" +
                                  props.match.params.changedSpecility +
                                  "/" +
                                  props.match.params.changedFramework,
                              }}
                            >
                              <button
                                className="btn w-sm mb-1 btn-sm btn-danger"
                                onClick={() => setShowReasonInput(true)}
                                disabled={
                                  filesAndDocs[currentDoc].documentLink != null
                                    ? false
                                    : true
                                }
                              >
                                Decline
                              </button>
                            </Link>
                          </div>
                        ) : null}

                        {/* //************* Send to Audit button for Heahunting/Registration/Maintenance consultants********** */}
                        {/* //************* Also only showing for the candidates who are in registration Work Flow Status********** */}
                        {/* since this candidate on registration status any conultant can and should send this candidate to the audit stage.so any consultant can send the candidate to the audit */}

                        {candidateServiceType == "TEMPORARY" &&
                          workFlowStatus === "REGISTRATION" &&
                          (checkPermissions("HEADHUNTER_TEMP").length > 0 ||
                            checkPermissions("REGISTRATION_TEMP").length > 0 ||
                            checkPermissions("MAINTENANCE_TEMP").length >
                              0) && (
                            <div className="col-8.5 col-sm-8 col-md-12 ">
                              <button
                                className="btn w-sm mb-1 btn-sm btn-warning"
                                onClick={() =>
                                  setShowRejectModal(!showRejectModal)
                                }
                              >
                                Send To Audit
                              </button>
                            </div>
                          )}

                        {/* //************* Review button for candidates in audit Work Flow Status can see only for
                         Heahunting/Registration/Maintenance consultants********** */}

                        {candidateServiceType == "TEMPORARY" &&
                          workFlowStatus === "AUDIT" &&
                          (checkPermissions("AUDIT_TEMP").length > 0 ||
                            checkPermissions("BOOKING_TEMP").length > 0) && (
                            <div className="col-8.5 col-sm-8  col-md-12 ">
                              <button
                                className="btn w-sm mb-1 btn-sm btn-warning"
                                onClick={() =>
                                  setShowRejectModal(!showRejectModal)
                                }
                              >
                                Review
                              </button>
                            </div>
                          )}
                      </div>
                      <ModalComponent
                        showRejectModal={showRejectModal}
                        show={showRejectModal}
                        header="Review documents"
                        className="modal-dialog modal-lg"
                        closeModal={toggleR}
                      >
                        <AuditReject
                          filesAndDocs={filesAndDocs}
                          selecetData={props}
                          incomingPage={"docView"}
                          candidateId={props.match.params.candidateId}
                          candidateServiceType={candidateServiceType}
                          workFlowStatus={workFlowStatus}
                          checkPermissions={checkPermissions}
                        />
                        <div className="mt-3">
                          {(checkPermissions("HEADHUNTER_TEMP").length > 0 ||
                            checkPermissions("REGISTRATION_TEMP").length > 0) &&
                            candidateServiceType == "TEMPORARY" &&
                            workFlowStatus === "REGISTRATION" && (
                              <Button
                                color="success"
                                // onClick={() =>
                                //   setShowAssignConsultantModal(
                                //     !showAssignConsultantModal
                                //   )
                                // }
                                onClick={sendToAudit}
                              >
                                Send To Audit
                                {/* {workFlowStatus === "AUDIT" ? "Verify" : null} */}
                              </Button>
                            )}

                          {/* //************* Verify button for audit consultants to verify the Candidate Profile********** */}

                          {candidateServiceType == "TEMPORARY" &&
                            workFlowStatus === "AUDIT" &&
                            checkPermissions("AUDIT_TEMP").length > 0 && (
                              <>
                                <Button
                                  color="success"
                                  className="ml-1"
                                  onClick={() =>
                                    setShowAssignConsultantModal(
                                      !showAssignConsultantModal
                                    )
                                  }
                                  //onClick={updateWorkFlowStatus}
                                >
                                  Verify
                                </Button>

                                <Button
                                  color="danger"
                                  className="ml-1"
                                  onClick={sendBackToRegistration}
                                  //onClick={updateWorkFlowStatus}
                                >
                                  Reject
                                </Button>
                              </>
                            )}

                          <Button
                            className="ml-1"
                            color="secondary"
                            onClick={toggleR}
                          >
                            Close
                          </Button>
                        </div>
                      </ModalComponent>
                      <ModalComponent
                        show={showAssignConsultantModal}
                        header="Assign a consultant"
                        closeModal={() => setShowAssignConsultantModal(false)}
                      >
                        <AssignConsultant
                          onCreate={(data) => sendToMaintenance(data)}
                          workflowToBe="MAINTENANCE"
                          closeModal={() => setShowAssignConsultantModal(false)}
                        />
                      </ModalComponent>

                      <ModalComponent
                        show={showReasonInput}
                        header="Reason for rejecting"
                        closeModal={toggle}
                      >
                        <Form>
                          <FormGroup>
                            <Alert color="danger">
                              <FontAwesomeIcon
                                icon={faExclamationCircle}
                                className="mr-2"
                              ></FontAwesomeIcon>
                              Please state the reason for rejecting the document
                            </Alert>
                            <Input
                              type="textarea"
                              rows="5"
                              onChange={(e) => setRejectReason(e.target.value)}
                            ></Input>
                          </FormGroup>
                          <Row className="justify-content-end">
                            <Button
                              color="success"
                              className="mr-2"
                              onClick={recordRejectReason}
                            >
                              Save
                            </Button>
                            <Button
                              color="secondary"
                              className="mr-3"
                              onClick={toggle}
                            >
                              Cancel
                            </Button>
                          </Row>
                        </Form>
                      </ModalComponent>

                      <ModalComponent
                        show={openModal}
                        header="Archived Documents"
                        closeModal={() => setOpenModal(false)}
                        size={"lg"}
                      >
                        <Form>
                          <FormGroup>
                            <div className="row">
                              <div className="col-md-4 font-weight-bold ">
                                Document Name
                              </div>
                              <div className="col-md-3 font-weight-bold text-center">
                                Issued Date
                              </div>
                              <div className="col-md-3 font-weight-bold text-center">
                                Expired Date
                              </div>
                              <div className="col-md-2 font-weight-bold text-center">
                                Action
                              </div>
                            </div>
                            <div>
                              {archivedDocumentsUrls !== undefined &&
                                archivedDocumentsUrls.map((document, id) => {
                                  return (
                                    <div
                                      key={id}
                                      className={`row text-sm mt-2 ${
                                        id % 2 === 0 ? "bg-light--lt" : ""
                                      }`}
                                      style={{ padding: "10px" }}
                                    >
                                      <div
                                        className="col-md-4 pointer"
                                        onClick={() => onSelect(document)}
                                      >
                                        {id + 1}.&nbsp;
                                        {filesAndDocs[currentDoc]?.documentName}
                                      </div>
                                      <div className="col-md-3 text-center ">
                                        {moment(document.issueDate).format(
                                          "YYYY"
                                        ) === "1900"
                                          ? "N/A"
                                          : moment(document.issueDate).format(
                                              "MM/DD/YYYY"
                                            )}
                                      </div>
                                      <div className="col-md-3 text-center ">
                                        {moment(document.expiredDate).format(
                                          "YYYY"
                                        ) === "2500"
                                          ? "N/A"
                                          : moment(document.expiredDate).format(
                                              "MM/DD/YYYY"
                                            )}
                                      </div>
                                      <div className="col-md-2 text-center pointer ">
                                        <Button
                                          onClick={() => {
                                            deleteArchiveDoc(document?.id);
                                          }}
                                          color="danger"
                                        >
                                          <FontAwesomeIcon icon={faTrash} />
                                        </Button>

                                        <div></div>
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </FormGroup>
                          <div className="d-flex justify-content-end">
                            {archivedDocumentsUrls.length > 0 && (
                              <Button
                                className="mx-2"
                                onClick={() =>
                                  downloadArchiveDocs(archivedDocumentsUrls)
                                }
                                color="success"
                              >
                                <FontAwesomeIcon
                                  icon={faFileDownload}
                                  className="mx-2"
                                ></FontAwesomeIcon>
                                Download Archives
                              </Button>
                            )}

                            <Button
                              color="danger"
                              onClick={() => setOpenModal(false)}
                            >
                              Close
                            </Button>
                          </div>
                        </Form>
                        <ModalComponent
                          show={
                            docsLoading.status &&
                            docsLoading.type == "MULTIPLE" &&
                            docsLoading.docType == "ARCHIVE"
                          }
                        >
                          <DocumentLoader onCancel={cancelDownload} />
                        </ModalComponent>
                        <ModalComponent
                          show={
                            docsLoading.status &&
                            docsLoading.type == "SINGLE" &&
                            docsLoading.docType == "ARCHIVE"
                          }
                        >
                          <DocumentLoader
                            onCancel={handleCancelClick}
                            showCancel={true}
                          />
                        </ModalComponent>
                      </ModalComponent>
                      <ModalComponent show={uploading}>
                        <DocumentLoader
                          onCancel={handleCancelClick}
                          showCancel={false}
                          isDownloading={false}
                        />
                      </ModalComponent>

                      <DeleteDocument
                        show={showDeleteDocument}
                        incommingPage={"docViewDocument"}
                        isConfirm={() => {
                          handleDeleteDocument();
                          setShowDeleteDocument(!showDeleteDocument);
                        }}
                        closeModal={() => setShowDeleteDocument(false)}
                      />

                      <ModalComponent
                        show={openSelectedArchivedDocumentModal}
                        header="Selected Document"
                        closeModal={() =>
                          setOpenSelectedArchivedDocumentModal(false)
                        }
                        size={"lg"}
                      >
                        <div>
                          {selectedArchivedDocuemnt && (
                            <div className="d-flex justify-content-center align-items-center flex-column">
                              <div>
                                <label for="file-input">
                                  {selectedArchivedDocuemntType != "pdf" ? (
                                    <img
                                      src={selectedArchivedDocuemnt}
                                      width={"500px"}
                                      // height={"700px"}
                                      // onClick={(data)=>onSelect(data)}
                                      style={{ border: "1px solid #ddd" }}
                                    ></img>
                                  ) : (
                                    <div className="justify-content-center d-flex  flex-column align-items-center">
                                      <div height="1000px">
                                        <Document
                                          file={selectedArchivedDocuemnt}
                                          onLoadSuccess={onDocumentLoadSuccess}
                                        >
                                          <Page
                                            pageNumber={pageNumber}
                                            width={400}
                                            height={400}
                                          />
                                        </Document>
                                      </div>
                                      <div className="d-flex align-items-center justify-content-center">
                                        <div style={{ width: "5px" }}>
                                          <FontAwesomeIcon
                                            icon={faAngleLeft}
                                            onClick={pdfPreviousPage}
                                            className="  btn-raised btn-wave pointer shadow-none"
                                          />
                                        </div>
                                        <div className="m-2">
                                          Page {pageNumber} of
                                        </div>
                                        <div style={{ width: "5px" }}>
                                          <FontAwesomeIcon
                                            icon={faAngleRight}
                                            className="  btn-raised btn-wave pointer shadow-none"
                                            onClick={pdfNextPage}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </label>
                              </div>
                            </div>
                          )}
                          <Row className="justify-content-center">
                            <Button
                              color="danger"
                              onClick={() =>
                                setOpenSelectedArchivedDocumentModal(false)
                              }
                            >
                              Cancel
                            </Button>
                          </Row>
                        </div>
                      </ModalComponent>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {})(DocView);
