import { connect } from "react-redux";
import AvatarMexxar from "../../../util/common/avatarMexxar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faCalendarAlt,
  faPoundSign,
  faEnvelope,
  faHeart,
  faHeartBroken,
  faUserAlt,
  faStickyNote,
  faClipboardList,
  faCalendarTimes,
  faBars,
  faArrowAltCircleUp,
  faArrowAltCircleDown,
  faStar,
  faTimesCircle,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Alert, Badge, Button, Label, UncontrolledTooltip } from "reactstrap";
import { Link } from "react-router-dom";
import loading from "../../../../src/images/puff.svg";
import moment from "moment";
import { _, isNull } from "lodash";
import { useState } from "react";
import ModalComponent from "../../../components/Modal/modalComponent";
import { HOSTMexxar } from "../../../configs/api-config";
import axios from "axios";
import useDidMountEffect from "../../../components/Modal/ModalBody/intialRunUseEffect";

function BookingWorkspaceCommon(props) {
  const {
    theme,
    showAdvanceColumn,
    rows,
    setRowID,
    searchQuery,
    setSelectedCandidate,
    showEmailModal,
    setShowEmailModal,
    calendarShow,
    setCalendarShow,
    setClientRateCandidate,
    setShowHospitalRateModal,
    showHospitalRateModal,
    setShowLikeModal,
    showLikeModal,
    setShowDislikeModal,
    showDislikeModal,
    showCallShellModal,
  } = props;

  const filterByName = (name, dataSet) => {
    let filtered = dataSet;
    if (name !== "") {
      filtered = dataSet.filter((item) => {
        let fullName = item.firstName + item.middleName + item.lastName;
        let firstLastName = item.firstName + item.lastName;

        let searchableString = `${fullName} ${firstLastName} ${item.candidate.firstName} ${item.candidate.lastName} ${item.candidate.email} ${item.candidate.primaryPhone}`;
        let p = searchableString;
        return p.toLowerCase().includes(name.toLowerCase());
      });
    }
    return filtered;
  };

  const [visibleColumns, setVisibleColumns] = useState({
    options: true,
    name: true,
    like: true,
    dislike: true,
    // specialities: true,
    // lastContact: true,
  });

  const toggleColumn = (column) => {
    setVisibleColumns((prev) => ({
      ...prev,
      [column]: !prev[column],
    }));
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showConfigureModal, setShowConfigureModal] = useState(false);
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [showEmployee, setShowEmployee] = useState(false);
  const [showConfigureButton, setShowConfigureButton] = useState(true);
  const [showArrowUp, setShowArrowUp] = useState(false);
  const [selecedcandidate, setSelectedCandidateNote] = useState([]);
  const [notes, setNotes] = useState([]);

  // const [showConfigureButton, setShowConfigureButton] = useState(false); // Default to false
  // const [showConfigureModal, setShowConfigureModal] = useState(false);

  const handleToggle = (value) => {
    setShowConfigureButton(value); // Optionally toggle the button visibility
  };

  // const handleToggle = (show) => {
  //   setShowEmployee(show);
  //   setShowConfigureButton(!show);
  // };
  const handleAvatarClick = (candidateId, primaryPhone) => {
    console.log("candidateId", candidateId);
    console.log("primaryPhone", primaryPhone);
    if (!candidateId) {
      console.error("Candidate ID is missing.");
      return;
    }
    if (!primaryPhone) {
      console.warn("Primary phone is missing.");
    }
    const profileUrl = `candidate-profile/${candidateId}/ ${primaryPhone}`;
    window.open(profileUrl, "_blank");
  };
  const getAllNotes = () => {
    axios
      .get(
        HOSTMexxar +
          "callLogs/filter?candidateId=" +
          selecedcandidate +
          "&offset=1000"
      )
      .then((res) => {
        // console.log("booking workspace data", res.data.body);

        let response = res?.data?.body?.content;
        setNotes(response);
        console.log(response);
      })
      .catch((err) => {
        //console.log(err)
        // ToastCommon(true, "error", addToast);
      });
  };

  useDidMountEffect(() => {
    getAllNotes();
  }, [selecedcandidate]);

  return (
    <>
      <div>
        <FontAwesomeIcon
          icon={showArrowUp ? faArrowAltCircleDown : faArrowAltCircleUp}
          className="btn-raised"
          id="toggleArrow"
          style={{ boxShadow: "none", cursor: "pointer" }}
          onClick={() => {
            handleToggle(!showArrowUp);
            setShowArrowUp(!showArrowUp);
          }}
        />
        <UncontrolledTooltip placement="left" target="toggleArrow">
          {showArrowUp
            ? "Click to show consultants"
            : "Click to hide consultants"}
        </UncontrolledTooltip>

        {!showConfigureButton && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <Button
              onClick={() => {
                setShowConfigureModal(true);
              }}
              color="success m-1 btn-sm"
              style={{ width: "200px" }}
              id="configureColumns"
            >
              <small>Configure Column</small>
            </Button>
          </div>
        )}
      </div>

      <div
        className="table-container"
        style={{
          position: "absolute",
          width: "100vw",
          height: "100vh",
          overflow: "auto",
          // padding: "10px",
          backgroundColor: "#f8f9fa",
        }}
      >
        <table className="custom-table">
          <thead
            className="table-head"
            style={{
              position: "sticky",
              top: 0,
              backgroundColor: "#fff",
              zIndex: 1,
            }}
          >
            <tr>
              {visibleColumns.options && (
                <th className="header-icon option-icon-candidate">
                  <FontAwesomeIcon icon={faBars} title="Options" />
                </th>
              )}
              {visibleColumns.name && (
                <th className="header-icon header-icon-candidate">
                  <FontAwesomeIcon icon={faUserAlt} title="Candidate Name" />
                </th>
              )}
              {visibleColumns.like && (
                <th className="header-icon heart-icon-candidate">
                  <FontAwesomeIcon
                    icon={faHeart}
                    color="#FF255C"
                    title="Like Department"
                  />
                </th>
              )}
              {visibleColumns.dislike && (
                <th className="header-icon heart-broken-icon-candidate">
                  <FontAwesomeIcon
                    icon={faHeartBroken}
                    color="#00B4FF"
                    title="Dislike Department"
                  />
                </th>
              )}

              {filterByName(searchQuery, rows)[0]?.dayData.map((day, i) => (
                <th
                  key={i}
                  className="header-day"
                  style={{ width: "70px", height: "10px" }}
                >
                  <small>{moment(day.date).format("ddd")}</small>
                  <br />
                  {moment(day.date).format("DD")}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {rows ? (
              filterByName(searchQuery, rows).map((row, i) => (
                <tr key={i} className="row-item" style={{ height: "10px" }}>
                  {visibleColumns.options && (
                    <td style={{ height: "10px", verticalAlign: "middle" }}>
                      <div className="icon-group">
                        <AvatarMexxar
                          candidateId={row?.candidate?.id}
                          primaryPhone={row?.candidate?.primaryPhone}
                          avatar={row?.candidate?.avatar}
                          gender={row?.candidate?.gender}
                          avatarSize={"w-20"}
                        />

                        <Link to={`/dashboard/call/${row?.candidate?.id}`}>
                          <FontAwesomeIcon
                            icon={faPhone}
                            className="icon-action text-success"
                            title="Call"
                          />
                        </Link>
                        <FontAwesomeIcon
                          icon={faEnvelope}
                          className="icon-action text-info"
                          title="Email"
                          onClick={() => {
                            setSelectedCandidate(row?.candidate);
                            setShowEmailModal(!showEmailModal);
                          }}
                        />
                        <Link
                          to={`/dashboard/candidate-profile/${row?.candidate?.id}/+4545454545/calendar`}
                        >
                          <FontAwesomeIcon
                            icon={faCalendarAlt}
                            className="icon-action text-warning"
                            title="Calendar"
                          />
                        </Link>
                        <FontAwesomeIcon
                          icon={faPoundSign}
                          className="icon-action text-primary"
                          title="Rates"
                          onClick={() => {
                            setClientRateCandidate(row);
                            setShowHospitalRateModal(!showHospitalRateModal);
                          }}
                        />
                        <FontAwesomeIcon
                          icon={faStickyNote}
                          title="Notes"
                          onClick={() => {
                            setSelectedCandidateNote(row?.candidate?.id);
                            setShowNotesModal(true);
                          }}
                        />
                      </div>
                    </td>
                  )}
                  {visibleColumns.name && (
                    <td>
                      <Link
                        onClick={() =>
                          handleAvatarClick(
                            row?.candidate?.id,
                            row?.candidate?.primaryPhone
                          )
                        }
                      >
                        {row?.candidate?.firstName} {row?.candidate?.lastName}
                      </Link>
                    </td>
                  )}
                  {visibleColumns.like && (
                    <td className="text-center align-middle">
                      <FontAwesomeIcon
                        icon={faHeart}
                        color="#FF255C"
                        className="icon-action pointer"
                        title="Add Likes"
                        onClick={() => {
                          setRowID(row?.candidate?.id);
                          setShowLikeModal(!showLikeModal);
                        }}
                      />
                    </td>
                  )}

                  {visibleColumns.dislike && (
                    <td className="text-center align-middle">
                      <FontAwesomeIcon
                        icon={faHeartBroken}
                        color="#00B4FF"
                        className="icon-action pointer"
                        title="Add Dislikes"
                        onClick={() => {
                          setRowID(row?.candidate?.id);
                          setShowDislikeModal(!showDislikeModal);
                        }}
                      />
                      {row?.candidate?.dislikeWards?.map((ward, i) => (
                        <span key={i} className="badge badge-dislike">
                          {ward.wardName}
                        </span>
                      ))}
                    </td>
                  )}
                  {/* {visibleColumns.specialities && <td></td>} */}
                  {/* {visibleColumns.lastContact && <td></td>} */}
                  {row?.dayData.map((day, j) => (
                    <td
                      key={j}
                      className={`btn-raised btn-wave text-center
                                         ${
                                           day.availabilityStatus ===
                                             "AVAILABLE" &&
                                           day.bookingCallStatus ===
                                             "CALL_ME_BEFORE_BOOKING"
                                             ? "bg-warning"
                                             : ""
                                         }
                                         ${
                                           day.availabilityStatus ===
                                             "AVAILABLE" &&
                                           day.bookingCallStatus ===
                                             "DO_NOT_CALL_ME_BEFORE_BOOKING"
                                             ? "bg-success"
                                             : ""
                                         }
                                         ${
                                           day.availabilityStatus === "VACATION"
                                             ? "bg-danger"
                                             : ""
                                         }
                                         ${
                                           day?.availabilityStatus ===
                                             "BOOKED" &&
                                           day?.bookingStatus === "CONFIRM" &&
                                           day?.shift?.name === "N" &&
                                           "bg-booking-night"
                                         }
                                         ${
                                           day?.availabilityStatus ===
                                             "BOOKED" &&
                                           day?.bookingStatus === "CONFIRM" &&
                                           day?.shift?.name === "E" &&
                                           "bg-booking-e"
                                         }
                                         ${
                                           day?.availabilityStatus ===
                                             "BOOKED" &&
                                           day?.bookingStatus === "CONFIRM" &&
                                           day?.shift?.name === "LD" &&
                                           "bg-booking-LD"
                                         }
                                         ${
                                           day?.availabilityStatus ===
                                             "BOOKED" &&
                                           day?.bookingStatus === "CONFIRM" &&
                                           day?.shift?.name === "TW" &&
                                           "bg-booking-TW"
                                         }
                                         ${
                                           day?.availabilityStatus ===
                                             "BOOKED" &&
                                           day?.bookingStatus === "CONFIRM" &&
                                           day?.shift?.name === "L" &&
                                           "bg-booking-L"
                                         }
                                         ${
                                           day?.availabilityStatus ===
                                             "BOOKED" &&
                                           day?.bookingStatus ===
                                             "UNCONFIRMED" &&
                                           "bg-warning text-danger"
                                         }
                                     `}
                      style={{
                        border: "1px solid #E5E5E5", // Adjust color and thickness as needed
                      }}
                      onClick={() => {
                        setRowID(row?.candidate.id);
                        showCallShellModal(day, row);
                        setSelectedCandidate(row?.candidate);
                      }}
                    >
                      {day.availabilityStatus !== "VACATION" ? (
                        isNull(day.hospitalName) ? (
                          isNull(day.shift) ? (
                            ""
                          ) : (
                            day.shift.name
                          )
                        ) : (
                          "B"
                        )
                      ) : (
                        <>
                          <span
                            className="position-absolute top-50 start-50 translate-middle"
                            style={{
                              fontSize: "1rem",
                              color: "white",
                              transform: "translate(-50%, -50%)",
                            }}
                          >
                            &#10006;
                          </span>
                        </>
                      )}
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="100%" className="text-center">
                  <img src={loading} alt="Loading" style={{ height: 100 }} />
                </td>
              </tr>
            )}
          </tbody>
        </table>{" "}
      </div>

      <ModalComponent
        className="modal-dialog modal-lg"
        show={showConfigureModal}
        header="Configure Columns"
        closeModal={() => setShowConfigureModal(false)}
      >
        <div
          style={{
            padding: "16px",
            border: "1px solid #ccc",
            borderRadius: "8px",
            backgroundColor: "#f9f9f9",
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(120px, 1fr))",
            gap: "12px",
          }}
        >
          {Object.keys(visibleColumns).map((key) => (
            <label
              key={key}
              style={{
                padding: "8px",
                border: "1px solid #ddd",
                borderRadius: "6px",
                textAlign: "center",
                backgroundColor: "#fff",
                transition: "background-color 0.2s, box-shadow 0.2s",
                cursor: "pointer",
              }}
              onMouseOver={(e) =>
                (e.currentTarget.style.backgroundColor = "#e6f7ff")
              }
              onMouseOut={(e) =>
                (e.currentTarget.style.backgroundColor = "#fff")
              }
            >
              <input
                type="checkbox"
                checked={visibleColumns[key]}
                onChange={() => toggleColumn(key)}
                style={{ marginRight: "6px" }}
              />
              {key.charAt(0).toUpperCase() + key.slice(1)}
            </label>
          ))}
        </div>
      </ModalComponent>

      <ModalComponent
        className="modal-dialog modal-lg"
        show={showNotesModal}
        header="Notes"
        closeModal={() => setShowNotesModal(false)}
      >
        <div>
          {Array.isArray(notes) && notes.length > 0 ? (
            notes.map((row) => (
              <div
                className="card mb-3"
                key={row.id}
                style={{
                  backgroundColor: "#ffffff",
                  border: "1px solid #e0e0e0",
                  borderRadius: "12px",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  overflow: "hidden",
                }}
              >
                <div className="p-4">
                  {/* Candidate Status */}
                  <div className="d-flex align-items-center mb-3">
                    {row.candidateStatus === "DORMANT" ? (
                      <Alert
                        color="warning"
                        style={{
                          backgroundColor: "#fff3cd",
                          borderColor: "#ffeeba",
                          color: "#856404",
                          padding: "8px 12px",
                          borderRadius: "8px",
                          marginBottom: 0,
                        }}
                      >
                        <FontAwesomeIcon
                          className="mr-2"
                          icon={faTimesCircle}
                        />
                        This candidate is dormant
                      </Alert>
                    ) : row.candidateStatus === "DO_NOT_CALL" ? (
                      <Alert
                        color="danger"
                        style={{
                          backgroundColor: "#f8d7da",
                          borderColor: "#f5c6cb",
                          color: "#721c24",
                          padding: "8px 12px",
                          borderRadius: "8px",
                          marginBottom: 0,
                        }}
                      >
                        <FontAwesomeIcon
                          className="mr-2"
                          icon={faExclamationCircle}
                        />
                        Candidate is requested not to contact
                      </Alert>
                    ) : (
                      <Badge
                        className="text-white px-3 py-2"
                        style={{
                          backgroundColor:
                            row.candidateStatus === "ACTIVE"
                              ? "#28a745"
                              : "#6c757d",
                          borderRadius: "8px",
                          fontSize: "0.85rem",
                        }}
                      >
                        {row.candidateStatus}
                      </Badge>
                    )}
                  </div>

                  {/* Candidate Details */}
                  <div className="mb-3">
                    <div
                      className="font-weight-bold text-dark"
                      style={{ fontSize: "1.1rem" }}
                    >
                      {row.candidateName || "Unknown Candidate"}
                    </div>
                    <div className="text-muted" style={{ fontSize: "0.9rem" }}>
                      {typeof row.reason === "string"
                        ? row.reason
                        : "No reason provided"}
                    </div>
                  </div>

                  {/* Timeline and Additional Info */}
                  <div
                    className="text-muted"
                    style={{
                      fontSize: "0.85rem",
                      lineHeight: "1.5",
                    }}
                  >
                    {row.startTime ? (
                      <>
                        <strong>Start Time:</strong>{" "}
                        {new Date(row.startTime).toLocaleString()}
                        <br />
                        <strong>Handled By:</strong>{" "}
                        {row.employeeName || "Unknown"}
                      </>
                    ) : (
                      "Unknown start time"
                    )}
                  </div>
                </div>

                {/* Footer Actions */}
                <div
                  className="d-flex justify-content-between align-items-center p-3"
                  style={{
                    backgroundColor: "#f9f9f9",
                    borderTop: "1px solid #e0e0e0",
                  }}
                >
                  <div>
                    <FontAwesomeIcon
                      icon={faStar}
                      className={`star shadow-none pointer ${
                        row.important ? "text-warning" : "text-muted"
                      }`}
                      style={{
                        fontSize: "1.2rem",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div>No notes available</div>
          )}
        </div>
      </ModalComponent>
    </>
  );
}

function mapStateToProps(state) {
  return {
    theme: state.theme.theme,
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {})(BookingWorkspaceCommon);
