import { connect } from "react-redux";
import AvatarMexxar from "../../../util/common/avatarMexxar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faCalendarAlt,
  faPoundSign,
  faEnvelope,
  faHeart,
  faHeartBroken,
  faUsers,
  faCalendar,
} from "@fortawesome/free-solid-svg-icons";
import { Label, UncontrolledTooltip } from "reactstrap";
import { Link } from "react-router-dom";
import loading from "../../../../src/images/puff.svg";
import moment from "moment";
import { _, isNull } from "lodash";

function BookingWorkspaceDefaultView(props) {
  const {
    theme,
    showAdvanceColumn,
    rows,
    setRowID,
    searchQuery,
    setSelectedCandidate,
    showEmailModal,
    setShowEmailModal,
    calendarShow,
    setCalendarShow,
    setClientRateCandidate,
    setShowHospitalRateModal,
    showHospitalRateModal,
    setShowLikeModal,
    showLikeModal,
    setShowDislikeModal,
    showDislikeModal,
    showCallShellModal,
  } = props;

  const filterByName = (name, dataSet) => {
    let filtered = dataSet;
    if (name !== "") {
      filtered = dataSet.filter((item) => {
        let fullName = item.firstName + item.middleName + item.lastName;
        let firstLastName = item.firstName + item.lastName;

        let searchableString = `${fullName} ${firstLastName} ${item.candidate.firstName} ${item.candidate.lastName} ${item.candidate.email} ${item.candidate.primaryPhone}`;
        let p = searchableString;
        return p.toLowerCase().includes(name.toLowerCase());
      });
    }
    return filtered;
  };

  return (
    <div className="scrollable-container">
      <div className=" d-flex ">
        <div>
          {showAdvanceColumn ? (
            <table className="booking-table-container-small ">
              <thead>
                <tr className="booking-tr-th">
                  <th
                    className={`${
                      theme === "bg-dark"
                        ? "booking-th booking-tr-th-child-one"
                        : "w-booking-th w-booking-tr-th-child-one"
                    }`}
                  >
                    Options
                  </th>
                </tr>
              </thead>
              <tbody>
                {rows !== undefined ? (
                  <>
                    {filterByName(searchQuery, rows).map((row, i) => (
                      <tr key={i}>
                         <td className="">
                          <div className="flex ">
                            <div className="d-flex justify-content-around align-items-center">
                              <div className="d-flex  ">
                                <AvatarMexxar
                                  candidateId={row?.candidate?.id}
                                  primaryPhone={row?.candidate?.primaryPhone}
                                  avatar={row?.candidate?.avatar}
                                  gender={row?.candidate?.gender}
                                  avatarSize={"w-20"}
                                />
                              </div>
                              <div className="mx-2 ">
                                <Link
                                  to={"/dashboard/call/" + row?.candidate?.id}
                                >
                                  <FontAwesomeIcon
                                    icon={faPhone}
                                    className="fa-lg mb-2 btn-raised btn-wave text-success  "
                                    id="call"
                                    style={{
                                      boxShadow: "none",
                                    }}
                                  />
                                </Link>
                                <UncontrolledTooltip
                                  placement="right"
                                  target="call"
                                >
                                  {row?.candidate?.primaryMobile}
                                </UncontrolledTooltip>
                              </div>
                              <div className="mx-2 ">
                                <FontAwesomeIcon
                                  onClick={() => {
                                    setSelectedCandidate(row?.candidate);
                                    setShowEmailModal(!showEmailModal);
                                  }}
                                  icon={faEnvelope}
                                  color="#b9c0d3"
                                  className="fa-lg mb-2 btn-raised btn-wave pointer "
                                  id="email"
                                  style={{
                                    boxShadow: "none",
                                  }}
                                />
                              </div>

                              <div className="mx-2  ">
                                <Link
                                  to={{
                                    pathname:
                                      "/dashboard/candidate-profile/" +
                                      row?.candidate?.id +
                                      "/+4545454545/calendar",
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={faCalendarAlt}
                                    color="#FF5603"
                                    className="fa-lg mb-2 btn-raised btn-wave pointer"
                                    style={{ boxShadow: "none" }}
                                    id="calendar"
                                  />
                                </Link>
                              </div>
                              <div className="mx-2  ">
                                <FontAwesomeIcon
                                  icon={faCalendar}
                                  onClick={() => {
                                    setCalendarShow(!calendarShow);
                                    setRowID(row?.candidate?.id);
                                    setSelectedCandidate(row?.candidate);
                                  }}
                                  color="#FF5603"
                                  className="fa-lg mb-2 btn-raised btn-wave pointer"
                                  style={{ boxShadow: "none" }}
                                  id="calendar"
                                />
                              </div>

                              <div className="mx-2  ">
                                <FontAwesomeIcon
                                  icon={faPoundSign}
                                  color="#FFBA00"
                                  className="fa-lg mb-2 btn-raised btn-wave pointer "
                                  id="rates"
                                  style={{
                                    boxShadow: "none",
                                  }}
                                  onClick={() => {
                                    setClientRateCandidate(row);
                                    setShowHospitalRateModal(
                                      !showHospitalRateModal
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <div>
                    <div>
                      <img src={loading} style={{ height: 100 }}></img>
                    </div>
                  </div>
                )}
              </tbody>
            </table>
          ) : null}
        </div>
        <div
          className={`${
            showAdvanceColumn
              ? "booking-table-container-wide"
              : "booking-table-container"
          }`}
        >
          <table>
            <thead>
              {rows !== undefined ? (
                <>
                  <tr className="booking-tr-th">
                    <th
                      className={`${
                        theme === "bg-dark"
                          ? "booking-th booking-tr-th-child-one"
                          : "w-booking-th w-booking-tr-th-child-one"
                      }`}
                    >
                      Candidate Name
                    </th>
                    <th
                      className={`${
                        theme === "bg-dark"
                          ? "booking-th booking-tr-th-child-two"
                          : "w-booking-th w-booking-tr-th-child-two"
                      }`}
                    >
                      <FontAwesomeIcon
                        color="#FF255C"
                        icon={faHeart}
                        className=" mr-2"
                        style={{
                          boxShadow: "none",
                        }}
                      ></FontAwesomeIcon>
                      Like Department
                    </th>
                    <th
                      className={`${
                        theme === "bg-dark"
                          ? "booking-th booking-tr-th-child-three"
                          : "w-booking-th w-booking-tr-th-child-three"
                      }`}
                    >
                      <FontAwesomeIcon
                        color="#00B4FF"
                        icon={faHeartBroken}
                        className=" mr-2 "
                        style={{
                          boxShadow: "none",
                        }}
                      />{" "}
                      Dislike Department
                    </th>
                    {filterByName(searchQuery, rows)[0] !== undefined ? (
                      <>
                        {filterByName(searchQuery, rows)[0].dayData.map(
                          (day, i) => (
                            <th
                              key={i}
                              className={`${
                                theme === "bg-dark"
                                  ? "booking-th booking-tr-th-child-four"
                                  : "w-booking-th w-booking-tr-th-child-four"
                              }`}
                            >
                              <small>{moment(day.date).format("ddd")}-</small>
                              {moment(day.date).format("DD")}
                            </th>
                          )
                        )}
                      </>
                    ) : (
                      <>
                        <div>
                          <img src={loading} style={{ height: 100 }}></img>
                        </div>
                      </>
                    )}
                  </tr>
                </>
              ) : (
                <div></div>
              )}
            </thead>

            <tbody>
              {rows !== undefined ? (
                <>
                  {filterByName(searchQuery, rows).map((row, i) => (
                    <tr key={i}>
                      <td
                        className={`${
                          theme === "bg-dark"
                            ? "booking-td booking-td-th-child-one"
                            : "w-booking-td w-booking-td-th-child-one"
                        }`}
                      >
                        <Link
                          to={{
                            pathname:
                              "/dashboard/candidate-profile/" +
                              row?.candidate?.id +
                              "/" +
                              row?.primaryPhone,
                          }}
                          className="text-color"
                        >
                          {row?.candidate?.firstName}&nbsp;
                          {row?.candidate?.lastName}
                        </Link>
                      </td>
                      <td
                        className={`${
                          theme === "bg-dark"
                            ? "booking-td booking-td-th-child-two"
                            : "w-booking-td w-booking-td-th-child-two"
                        }`}
                      >
                        <div className="d-flex justify-content-start">
                          <div>
                            {" "}
                            <FontAwesomeIcon
                              id="likeWardsRow"
                              color="#FF255C"
                              icon={faHeart}
                              className="fa-md btn-raised btn-wave mr-2 pointer"
                              style={{
                                boxShadow: "none",
                              }}
                              onClick={() => {
                                setRowID(row?.candidate?.id);
                                setShowLikeModal(!showLikeModal);
                              }}
                            />
                          </div>

                          <div>
                            {row?.candidate?.likeWards?.map((ward, i) => (
                              <Label className="mr-1" key={i}>
                                {ward.wardName}{" "}
                              </Label>
                            ))}
                          </div>
                        </div>
                      </td>

                      <td
                        className={`${
                          theme === "bg-dark"
                            ? "booking-td booking-td-th-child-three"
                            : "w-booking-td w-booking-td-th-child-three"
                        }`}
                      >
                        <div className="d-flex justify-content-start">
                          <div>
                            {" "}
                            <FontAwesomeIcon
                              color="#00B4FF"
                              icon={faHeartBroken}
                              className="fa-md btn-raised btn-wave mr-2 pointer"
                              style={{
                                boxShadow: "none",
                              }}
                              onClick={() => {
                                setRowID(row?.candidate?.id);
                                setShowDislikeModal(!showDislikeModal);
                              }}
                            />
                          </div>

                          <div>
                            {row?.candidate?.dislikeWards?.map((ward, i) => (
                              <Label className="mr-1" key={i}>
                                {ward?.wardName}
                              </Label>
                            ))}
                          </div>
                        </div>
                      </td>
                      {row?.dayData.map((day, i) => (
                        <td
                          key={i}
                          className={`booking-td  btn-raised btn-wave
                        ${
                          day.availabilityStatus === "AVAILABLE" &&
                          day.bookingCallStatus === "CALL_ME_BEFORE_BOOKING"
                            ? "bg-warning"
                            : ""
                        }
                        ${
                          day.availabilityStatus === "AVAILABLE" &&
                          day.bookingCallStatus ===
                            "DO_NOT_CALL_ME_BEFORE_BOOKING"
                            ? "bg-success"
                            : ""
                        }
                        ${
                          day.availabilityStatus === "VACATION"
                            ? "bg-danger"
                            : ""
                        }

                ${
                  day?.availabilityStatus === "BOOKED" &&
                  day?.bookingStatus === "CONFIRM" && day?.shift?.name === "N" &&
                  "bg-booking-night"
                }
                  ${
                  day?.availabilityStatus === "BOOKED" &&
                  day?.bookingStatus === "CONFIRM" && day?.shift?.name === "E" &&
                  "bg-booking-e"
                }
                ${
                  day?.availabilityStatus === "BOOKED" &&
                  day?.bookingStatus === "CONFIRM" && day?.shift?.name === "LD" &&
                  "bg-booking-LD"
                }
                  ${
                  day?.availabilityStatus === "BOOKED" &&
                  day?.bookingStatus === "CONFIRM" && day?.shift?.name === "TW" &&
                  "bg-booking-TW"
                }
                 ${
                  day?.availabilityStatus === "BOOKED" &&
                  day?.bookingStatus === "CONFIRM" && day?.shift?.name === "L" &&
                  "bg-booking-L"
                }
                ${
                  day?.availabilityStatus === "BOOKED" &&
                  day?.bookingStatus === "UNCONFIRMED" &&
                  "bg-warning text-danger "
                }
                `}
                          onClick={() => {
                            setRowID(row?.candidate.id);
                            showCallShellModal(day, row);
                            setSelectedCandidate(row?.candidate);
                          }}
                        >
                          {day.availabilityStatus !== "VACATION" ? (
                            isNull(day.hospitalName) ? (
                              isNull(day.shift) ? (
                                ""
                              ) : (
                                day.shift.name
                              )
                            ) : (
                              "B"
                            )
                          ) : (
                            <>
                              <span
                                className="position-absolute top-50 start-50 translate-middle"
                                style={{
                                  fontSize: "1rem",
                                  color: "white",
                                  transform: "translate(-50%, -50%)",
                                }}
                              >
                                &#10006;
                              </span>
                            </>
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </>
              ) : (
                <div>
                  <div className="d-flex justify-content-center justify-items-center">
                    <div>
                      <img src={loading} style={{ height: 100 }}></img>
                    </div>
                  </div>
                </div>
              )}
            </tbody>
          </table>{" "}
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    theme: state.theme.theme,
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {})(BookingWorkspaceDefaultView);
